import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faCheck, faCheckDouble, faPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Spinner, Alert } from 'react-bootstrap';
import { CLUB_URL, COUNTRY_URL, FOOTBALLPLAYERENDORSEMENTREQUEST_URL, GET_INSTITUITION_URL, LEAGUES_URL, PLAYERHISTORYCREATE_URL, PLAYERHISTORYTEAMLEAGUEUPDATE_URL, PLAYERHISTORYUPDATE_URL, PLAYERHISTORY_URL, PLAYER_URL, SEARCHAGENT_URL, SEARCHALLUSER_URL, SEARCHCOACH_URL, SEARCHPLAYER_URL, SENDMAIL_URL, TEAMS_URL, USER_URL, VIDEOCLIP_URL, optionsPosition, optionsPreferredFoot } from '../../services/Constants';
import AuthContext from '../../context/AuthContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { ProfileBuilderNavBar } from '../../components/ProfileBuilderNavBar';
import axios from 'axios';

export default function EditPlayerProfile() {

  let {
    user,
    authTokens,
    notifyRegistrationPersonalInfo,
    notifyCustomSuccessMessage,
    notifyError,
    notifyAuthenticateError,
    notifyCustomErrorMessage,
    notifyCustomWarningMessage
  } = useContext(AuthContext)

  const navigate = useNavigate();

  const [key, setKey] = useState('general');

  // State variable of search user profile in Navbar

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  // const [flag, setFlag] = useState(false);

  // State variable for managing search lov filter

  const [isTeamLOVloading, setIsTeamLOVLoading] = useState(false);
  const [searchTeamLOVData, setSearchTeamLOVData] = useState([]);
  const [isLeagueLOVloading, setIsLeagueLOVLoading] = useState(false);
  const [searchLeagueLOVData, setSearchLeagueLOVData] = useState([]);
  const [isCountryLOVloading, setIsCountryLOVLoading] = useState(false);
  const [searchCountryLOVData, setSearchCountryLOVData] = useState([]);

  // State variable for managing Select options 

  const [countryInfo, setCountryInfo] = useState([]);
  const [optionsCountry, setOptionsCountry] = useState([]);
  const [countryName, setCountryName] = useState('');
  const [leaguesInfo, setLeaguesInfo] = useState([]);
  const [optionsLeague, setOptionsLeague] = useState([]);
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [optionsTeam, setOptionsTeam] = useState([]);

  const optionsRegionType = [
    { value: 'International', label: 'International' },
    { value: 'Domestic', label: 'Domestic' }
  ];

  // State variable to manage form validation

  const [validatedPlayerInfo, setValidatedPlayerInfo] = useState(false);
  const [validatedCareerHistory, setValidatedCareerHistory] = useState(false);
  const [validatedVideos, setValidatedVideos] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // State varialabe to manage modal

  const [showAddGeneralModal, setShowAddGeneralModal] = useState(false)
  const [showEditGeneralModal, setShowEditGeneralModal] = useState(false)
  const [showAddCareerHistoryModal, setShowAddCareerHistoryModal] = useState(false)
  const [showEditCareerHistoryModal, setShowEditCareerHistoryModal] = useState(false)
  const [showAddVideosModal, setShowAddVideosModal] = useState(false)
  const [showEditVideosModal, setShowEditVideosModal] = useState(false)
  const [showModalEndorsement, setShowModalEndorsement] = useState(false);
  const [showModalEndorsementAll, setShowModalEndorsementAll] = useState(false);
  const [showEditEndorsementModal, setShowEditEndorsementModal] = useState(false);
  const [showEditAllEndorsementModal, setShowEditAllEndorsementModal] = useState(false);
  const [showModalAskForRegistration, setShowModalAskForRegistration] = useState(false);

  // State variable to manage user data

  const [userPersonalData, setUserPersonalData] = useState('')

  // State variable to manage sport profile type

  const [selectedPlayerSwitchOption, setSelectedPlayerSwitchOption] = useState(false);
  const [selectedCoachSwitchOption, setSelectedCoachSwitchOption] = useState(false);
  const [selectedAgentSwitchOption, setSelectedAgentSwitchOption] = useState(false);

  // State variable for managing player info

  const [playerInfo, setPlayerInfo] = useState('')
  const [playerId, setPlayerId] = useState('')
  const [primaryPosition, setPrimaryPosition] = useState('')
  const [secondaryPosition, setSecondaryPosition] = useState('')
  const [topSpeed, setTopSpeed] = useState('')
  const [myWorth, setMyWorth] = useState(null)
  const [prefferedFoot, setPrefferedFoot] = useState('')
  // const [teamName, setTeamName] = useState('')
  // const [injuryHistory, setInjuryHistory] = useState('')
  const [checked, setChecked] = useState(false);

  const [positionError, setPositionError] = useState('');
  const [preferredFootError, setPreferredFootError] = useState('');
  // const [clubError, setClubError] = useState('');

  // State variable for managing career history

  const [playerClub, setPlayerClub] = useState([])
  const [playerClubId, setPlayerClubId] = useState(null)
  const [teamName, setTeamName] = useState('')
  const [teamId, setTeamId] = useState(null)
  // const [period, setPeriod] = useState('')
  const [fromYear, setFromYear] = useState('')
  const [toYear, setToYear] = useState('')
  const [summary, setSummary] = useState('')
  const [gamesPlayed, setGamesPlayed] = useState('')
  const [goalsScored, setGoalsScored] = useState('')
  const [assists, setAssists] = useState('')
  const [passes, setPasses] = useState('')
  const [goalsSaved, setGoalsSaved] = useState('')
  const [interceptionsPerGame, setInterceptionsPerGame] = useState('')
  const [tackelsPerGame, setTackelsPerGame] = useState('')
  const [shotsPerGame, setShotsPerGame] = useState('')
  const [keyPassesPerGame, setKeyPassesPerGame] = useState('')
  const [dribblesPerGame, setDribblesPerGame] = useState('')
  const [cleanSheetsPerGame, setCleanSheetsPerGame] = useState('')
  const [yellowCard, setYellowCard] = useState('')
  const [redCard, setRedCard] = useState('')
  const [keyAchievements, setKeyAchievements] = useState('')
  const [leagueType, setLeagueType] = useState('')
  const [leagueName, setLeagueName] = useState('')
  const [leagueId, setLeagueId] = useState(null)
  const [currentRequestStatus, setCurrentRequestStatus] = useState('')
  const [remarks, setRemarks] = useState('')

  const [typeError, setTypeError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [teamError, setTeamError] = useState('');
  const [leagueError, setLeagueError] = useState('');
  // const [periodError, setPeriodError] = useState('');
  const [fromYearError, setFromYearError] = useState('');
  const [toYearError, setToYearError] = useState('');
  const [gamesPlayedError, setGamesPlayedError] = useState('');

  // State variable to manage video clip

  const [videoId, setVideoId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoTitleError, setVideoTitleError] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoUrlError, setVideoUrlError] = useState('');
  const [videoClip, setVideoClip] = useState([]);

  // State variable for managing endorsement request

  // const [profileTypeError, setProfileTypeError] = useState('');
  const [checkedEndorsement, setCheckedEndorsement] = useState(false);
  const [checkedClubEndorsement, setCheckedClubEndorsement] = useState(false);
  const [personNameEndorsement, setPersonNameEndorsement] = useState([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
  // const [updatePersonNameEndorsement, setUpdatePersonNameEndorsement] = useState([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
  const [updatePersonNameEndorsement, setUpdatePersonNameEndorsement] = useState([])
  // const [personNameEndorsement, setPersonNameEndorsement] = useState('')
  const [sportPersonData, setSportPersonData] = useState([])
  const [allSportPersonData, setAllSportPersonData] = useState([])
  // const [personNameError, setPersonNameError] = useState('')
  // const [emailId, setEmailId] = useState('')
  const [comments, setComments] = useState('')
  const [playerEndorsementRequest, setPlayerEndorsementRequest] = useState([])
  const [inputFieldDisabled, setInputFieldDisabled] = useState(false)
  const [isEndorsementRequestClubDisabled, setIsEndorsementRequestClubDisabled] = useState(false)
  const [isEmailFieldVisible, setIsEmailFieldVisible] = useState(false)
  const [isSpinnerLoadingVisible, setIsSpinnerLoadingVisible] = useState(false)
  const [searchUserInfo, setSearchUserInfo] = useState('')
  const [emailError, setEmailError] = useState('')
  const [askforRegistrationEmail, setAskforRegistrationEmail] = useState('')
  const [searchUserProfileType, setSearchUserProfileType] = useState('')

  // function responsible of search data with navigation

  const navigateToSearchDataWithParams = (item) => {
    // console.log('navigateToSearchDataWithParams');
    navigate(`/user/viewprofile?user_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    // console.log('navigateToSearchAllWithParams');
    // console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  // send invitaion link

  const handleOnClickSendInvitation = async () => {
    if (askforRegistrationEmail === '') {
      setEmailError('Email cannot be empty');
      return;
    }
    setIsSpinnerLoadingVisible(true);
    setInputFieldDisabled(true);
    let response = fetch(`${SENDMAIL_URL} `, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'to': askforRegistrationEmail,
        'from': user.first_name
      })
    });
    console.log('response:', response);
    // const data = (await response).json();
    // console.log('data:', data)
    const status = (await response).status;
    if (status === 200) {
      notifyCustomSuccessMessage('Email send successfully');
      // setAskforRegistrationEmail('');
      setEmailError('');
    } else {
      notifyCustomErrorMessage('Something went wrong. Try again later.');
    }
    setIsSpinnerLoadingVisible(false);
    setInputFieldDisabled(false);
  }

  // function responsible for getting data from backend

  const getCountries = async () => {
    // e.preventDefault();
    // console.log('get countries');
    let response = fetch(`${COUNTRY_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const countryData = (await data);
      // console.log('countryData: ', countryData);
      setCountryInfo(countryData);
      const processedOptions = countryData.map(item => ({
        label: item.country_name,
        value: item.country_name
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsCountry(processedOptions);
    } else {
      notifyError()
    }
  }

  const getTeams = async () => {
    // e.preventDefault();
    // console.log('get leagues');
    let response = fetch(`${TEAMS_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const teamData = (await data);
      // console.log('teamData: ', teamData);
      setTeamsInfo(teamData);
      const processedOptions = teamData.map(item => ({
        label: item.club_name,
        value: item.reg_id
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsTeam(processedOptions);
    } else {
      notifyError()
    }
  }

  const getLeagues = async () => {
    // e.preventDefault();
    // console.log('get leagues');
    let response = fetch(`${LEAGUES_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const leagueData = (await data);
      // console.log('leagueData: ', leagueData);
      setLeaguesInfo(leagueData);
      const processedOptions = leagueData.map(item => ({
        label: item.league_name,
        value: item.id
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsLeague(processedOptions);
    } else {
      notifyError()
    }
  }

  const getPlayerInfo = async (id) => {
    setPlayerId(id)
    let response = fetch(`${PLAYER_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const player = (await data)
    // console.log('player info:', player)
    const status = (await response).status
    if (status === 200) {
      setTopSpeed(player.top_speed)
      setMyWorth(player.my_worth)
      setChecked(player.is_open_for_hiring)
      setPrefferedFoot(player.preferred_foot)
      setPrimaryPosition(player.primary_position)
      setSecondaryPosition(player.secondary_position)
    } else {
      notifyError()
    }
  }

  const getPlayerClubInfo = async (id) => {
    setPlayerClubId(id)
    let response = fetch(`${CLUB_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const club = (await data)
    // console.log('Club History:', club)
    const status = (await response).status
    if (status === 200) {
      // setLeagueType({ "label": club.type, "value": club.type })
      setLeagueType(club.league_type)
      // setCountryName({ "label": club.country, "value": club.country })
      setCountryName(club.country_name)
      // setLeagueName({ "label": club.league, "value": club.league })
      setLeagueName(club.league_name)
      setLeagueId(club.league_id)
      setTeamName(club.club_name)
      setTeamId(club.club_id)
      setFromYear(club.from_year)
      setToYear(club.to_year)
      setGamesPlayed(club.games_played)
      setGoalsScored(club.club_goals)
      setAssists(club.club_assists)
      setPasses(club.club_passes)
      setGoalsSaved(club.club_saved_goals)
      setInterceptionsPerGame(club.interceptions_per_game)
      setTackelsPerGame(club.takles_per_game)
      setShotsPerGame(club.shots_per_game)
      setKeyPassesPerGame(club.key_passes_per_game)
      setDribblesPerGame(club.dribles_completed_per_game)
      setCleanSheetsPerGame(club.clean_sheets_per_game)
      setYellowCard(club.club_yellow_card)
      setRedCard(club.club_red_card)
      setKeyAchievements(club.achievements)
      setSummary(club.summary)
      setCurrentRequestStatus(club.status)
      setRemarks(club.remarks)
      // console.log('endorsement request: ', club.endorsement_request)
      if (club.endorsement_request.length > 0) {
        setInputFieldDisabled(true)
        setCheckedEndorsement(true)
        club.endorsement_request.forEach(request => {
          if (request.type === 'Club') {
            setCheckedClubEndorsement(true)
            setIsEndorsementRequestClubDisabled(true)
          }
        })
        setPlayerEndorsementRequest(club.endorsement_request)
      } else {
        setInputFieldDisabled(false)
        setCheckedEndorsement(false)
        setCheckedClubEndorsement(false)
        setPlayerEndorsementRequest([])
      }
      getCountries();
      getTeams();
      getLeagues();
      getInstitution(club.club_id);
    } else {
      notifyError();
    }
  }

  const getVideoClipInfo = async (id) => {
    setVideoId(id)
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const video = (await data)
    // console.log('video clip:', video)
    const status = (await response).status
    if (status === 200) {
      setVideoTitle(video.title)
      setVideoUrl(video.clip_url)
    } else {
      notifyError()
    }
  }

  const getInstitution = async (id) => {
    console.log('getInstitution function called')
    let response = fetch(`${GET_INSTITUITION_URL}${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response);
    // const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status;
    if (status === 200) {
      // const user = (await data)
      setIsEmailFieldVisible(false);
      // console.log('user found');
    } else if (status === 404) {
      setIsEmailFieldVisible(true);
    } else {
      notifyError();
    }
  }

  // functions responsible for adding data in backend

  const handleAddPlayerSubmit = async (e) => {
    e.preventDefault();
    setValidatedPlayerInfo(true);
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("user", user.user_id);
    if (primaryPosition === '') {
      setPositionError("Primary position can't be empty.")
      return
    }
    else
      formData.append("primary_position", primaryPosition);
    if (prefferedFoot === '') {
      setPreferredFootError("Preferred foot can't be empty.")
      return
    }
    else
      formData.append("preferred_foot", prefferedFoot);
    formData.append("secondary_position", secondaryPosition);
    if (topSpeed !== null)
      formData.append("top_speed", topSpeed);
    // if (teamName === '') {
    //   setClubError("Club Name can't be empty.")
    //   return
    // }
    // else
    //   formData.append("current_club", teamName);
    if (myWorth !== null)
      formData.append("my_worth", myWorth);
    // formData.append("injury_history", injuryHistory);
    formData.append("is_open_for_hiring", checked);
    let response = fetch(`${PLAYER_URL}/`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setPrimaryPosition('')
      // setDefaultPrimaryPosition('')
      setSecondaryPosition('')
      // setDefaultSecondaryPosition('')
      setTopSpeed(null)
      setPrefferedFoot('')
      // setDefaultPreferredFoot('')
      // setInjuryHistory('')
      // setTeamName('')
      // setDefaultTeamName('')
      setMyWorth(null)
      setChecked(false)
      setValidatedPlayerInfo(false)
      setShowAddGeneralModal(false)
    } else {
      notifyError()
    }
  };

  // const handleAddPlayerClubHistory = async (e) => {
  //   // console.log('handleAddPlayerClubHistory function called');
  //   setValidatedCareerHistory(true);
  //   const formData = new FormData();
  //   formData.append("sport_type", userPersonalData.sport_type);
  //   if (playerInfo) {
  //     formData.append("players", playerInfo.id);
  //   }
  //   if (leagueType !== '') {
  //     formData.append("league_type", leagueType);
  //     setTypeError('');
  //   } else {
  //     setTypeError('Type cannot be left empty.');
  //     return;
  //   }
  //   if (leagueType === 'Domestic') {
  //     if (countryName === '') {
  //       setCountryError('Country cannot be left empty.');
  //       return;
  //     }
  //     else {
  //       setCountryError('');
  //       formData.append("country_name", countryName);
  //     }
  //   } else {
  //     setCountryError('');
  //     formData.append("country_name", '');
  //   }
  //   if (leagueName !== '') {
  //     formData.append("league_name", leagueName);
  //   }
  //   if (leagueId !== null) {
  //     formData.append("league_id", leagueId);
  //   }else{
  //     formData.append("league_id", '');
  //   }
  //   if (teamName !== '') {
  //     // formData.append("club_id", teamId);
  //     formData.append("club_name", teamName);
  //     setTeamError('');
  //   } else {
  //     setTeamError('Team cannot be left empty.');
  //     return;
  //   }
  //   if (teamId !== null) {
  //     formData.append("club_id", teamId);
  //   } else {
  //     formData.append("club_id", 0);
  //   }
  //   if (fromYear !== '') {
  //     formData.append("from_year", fromYear);
  //     setFromYearError('');
  //   } else {
  //     setFromYearError('From year cannot be left empty.');
  //     return;
  //   }
  //   if (toYear !== '') {
  //     formData.append("to_year", toYear);
  //     setToYearError('');
  //   } else {
  //     setToYearError('To year cannot be left empty.');
  //     return;
  //   }
  //   if (gamesPlayed !== '') {
  //     formData.append("games_played", gamesPlayed);
  //     setGamesPlayedError('');
  //   } else {
  //     setGamesPlayedError('Games played cannot be left empty.');
  //     return;
  //   }
  //   if (goalsScored !== '') {
  //     formData.append("club_goals", goalsScored);
  //   } else {
  //     formData.append("club_goals", 0);
  //   }
  //   if (assists !== '') {
  //     formData.append("club_assists", assists);
  //   } else {
  //     formData.append("club_assists", 0);
  //   }
  //   if (passes !== '') {
  //     formData.append("club_passes", passes);
  //   } else {
  //     formData.append("club_passes", 0);
  //   }
  //   if (goalsSaved !== '') {
  //     formData.append("club_saved_goals", goalsSaved);
  //   } else {
  //     formData.append("club_saved_goals", 0);
  //   }
  //   if (interceptionsPerGame !== '') {
  //     formData.append("interceptions_per_game", interceptionsPerGame);
  //   } else {
  //     formData.append("interceptions_per_game", 0);
  //   }
  //   if (tackelsPerGame !== '') {
  //     formData.append("takles_per_game", tackelsPerGame);
  //   } else {
  //     formData.append("takles_per_game", 0);
  //   }
  //   if (shotsPerGame !== '') {
  //     formData.append("shots_per_game", shotsPerGame);
  //   } else {
  //     formData.append("shots_per_game", 0);
  //   }
  //   if (keyPassesPerGame !== '') {
  //     formData.append("key_passes_per_game", keyPassesPerGame);
  //   } else {
  //     formData.append("key_passes_per_game", 0);
  //   }
  //   if (dribblesPerGame !== '') {
  //     formData.append("dribles_completed_per_game", dribblesPerGame);
  //   } else {
  //     formData.append("dribles_completed_per_game", 0);
  //   }
  //   if (cleanSheetsPerGame != '') {
  //     formData.append("clean_sheets_per_game", cleanSheetsPerGame);
  //   } else {
  //     formData.append("clean_sheets_per_game", 0);
  //   }
  //   if (yellowCard !== '') {
  //     formData.append("club_yellow_card", yellowCard);
  //   } else {
  //     formData.append("club_yellow_card", 0);
  //   }
  //   if (redCard !== '') {
  //     formData.append("club_red_card", redCard);
  //   } else {
  //     formData.append("club_red_card", 0);
  //   }
  //   formData.append("summary", summary);
  //   formData.append("achievements", keyAchievements);
  //   if (personNameEndorsement.length > 0 && personNameEndorsement[0].type !== '') {
  //     formData.append("status", 'pending');
  //     formData.append("endorsement_request", personNameEndorsement);
  //   }else{
  //     formData.append("endorsement_request", []);
  //   }
  //   formData.append("remarks", remarks);
  //   if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'teamleague');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${PLAYERHISTORY_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'league');
  //     let response = fetch(`${PLAYERHISTORY_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
  //     formData.append("flag", 'team');
  //     formData.append("reg_id", uuidv4());
  //     // formData.append("country_name", userPersonalData.citizenship);
  //     let response = fetch(`${PLAYERHISTORY_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     let response = fetch(`${PLAYERHISTORYCREATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   }
  // };

  const handleAddPlayerClubHistory = async (e) => {
    // console.log('handleAddPlayerClubHistory function called');
    setValidatedCareerHistory(true);
    // const formData = new FormData();
    // formData.append("sport_type", userPersonalData.sport_type);
    // if (playerInfo) {
    //   formData.append("players", playerInfo.id);
    // }

    if (fromYear !== '') {
      // formData.append("from_year", fromYear);
      setFromYearError('');
    } else {
      setFromYearError('From year cannot be empty.');
      return;
    }
    if (toYear !== '') {
      // formData.append("to_year", toYear);
      setToYearError('');
    } else {
      setToYearError('To year cannot be empty.');
      return;
    }
    if (leagueType !== '') {
      // formData.append("league_type", leagueType);
      setTypeError('');
    } else {
      setTypeError('Type cannot be empty.');
      return;
    }
    if (leagueType === 'Domestic') {
      if (countryName === '') {
        setCountryError('Country cannot be empty.');
        return;
      }
      else {
        setCountryError('');
        // formData.append("country_name", countryName);
      }
    } else {
      setCountryError('');
      // formData.append("country_name", '');
    }
    if (teamName !== '') {
      // formData.append("club_id", teamId);
      // formData.append("club_name", teamName);
      setTeamError('');
    } else {
      setTeamError('Team cannot be empty.');
      return;
    }
    // if (leagueName !== '') {
    //   setLeagueError('');
    // } else {
    //   setLeagueError('League Name cannot be empty.');
    //   return;
    // }
    // if (teamId !== null) {
    //   formData.append("club_id", teamId);
    // } else {
    //   formData.append("club_id", 0);
    // }

    if (gamesPlayed !== '') {
      // formData.append("games_played", gamesPlayed);
      setGamesPlayedError('');
    } else {
      setGamesPlayedError('Games played cannot be empty.');
      return;
    }
    // if (checkedEndorsement && isEmailFieldVisible) {
    //   if (emailId === '')
    //     return;
    // }
    setIsSpinnerLoadingVisible(true);
    if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
      // formData.append("flag", 'teamleague');
      // formData.append("reg_id", uuidv4());
      let data;
      if (checkedEndorsement) {
        if (personNameEndorsement.length > 0 && personNameEndorsement[0].to_endorser_email === '') {
          notifyCustomWarningMessage('Please select the endorser name.');
          setIsSpinnerLoadingVisible(false);
          return;
        }
        else {
          data = {
            career_history: {
              flag: 'teamleague',
              reg_id: uuidv4(),
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type,
              players: playerInfo.id
            },
            // endorsement_request_to_club: !isEmailFieldVisible ? {
            //   to_endorser_email: '',
            //   to_endorser: '',
            //   from_endorsee: user.user_id,
            //   type: 'Club',
            //   status: 'pending',
            //   comments: comments,
            //   remarks: '',
            //   player_career_history: ''
            // } : '',
            endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : ''
          };
        }
      } else {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          // endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setPersonNameEndorsement('')
          setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          // setEmailId('')
          setAskforRegistrationEmail('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
      // formData.append("flag", 'league');
      let data;
      if (checkedEndorsement) {
        if (checkedClubEndorsement) {
          // if (personNameEndorsement.length >= 0 && personNameEndorsement[0].to_endorser_email === '') {
          //   notifyCustomWarningMessage('Please select the endorser name.');
          // }
          // else {
          data = {
            career_history: {
              flag: 'league',
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type,
              players: playerInfo.id
            },
            endorsement_request_to_club: {
              to_endorser_email: '',
              to_endorser: '',
              from_endorsee: user.user_id,
              type: 'Club',
              status: 'pending',
              comments: comments,
              remarks: '',
              player_career_history: '',
              reg_id: teamId
            },
            endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : ''
          };
          // }
        } else {
          if (personNameEndorsement.length > 0 && personNameEndorsement[0].to_endorser_email === '') {
            notifyCustomWarningMessage('Please select the endorser name.');
            setIsSpinnerLoadingVisible(false);
            return;
          }
          else {
            data = {
              career_history: {
                flag: 'league',
                club_id: teamId,
                club_name: teamName,
                from_year: fromYear,
                to_year: toYear,
                games_played: gamesPlayed,
                club_goals: goalsScored !== '' ? goalsScored : 0,
                club_assists: assists !== '' ? assists : 0,
                club_passes: passes !== '' ? passes : 0,
                club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
                interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
                takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
                shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
                key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
                dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
                clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
                club_yellow_card: yellowCard !== '' ? yellowCard : 0,
                club_red_card: redCard !== '' ? redCard : 0,
                league_id: leagueId,
                league_name: leagueName,
                country_name: countryName,
                league_type: leagueType,
                achievements: keyAchievements,
                summary: summary,
                sport_type: userPersonalData.sport_type,
                players: playerInfo.id
              },
              endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
              endorsement_request_to_club: ''
            };
          }
        }
      } else {
        data = {
          career_history: {
            flag: 'league',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setPersonNameEndorsement('')
          setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          // setEmailId('')
          setAskforRegistrationEmail('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName === '') {
      // formData.append("flag", 'league');
      let data;
      if (checkedEndorsement) {
        if (checkedClubEndorsement) {
          // if (personNameEndorsement.length >= 0 && personNameEndorsement[0].to_endorser_email === '') {
          //   notifyCustomWarningMessage('Please select the endorser name.');
          // }
          // else {
          data = {
            career_history: {
              flag: 'leaguenull',
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type,
              players: playerInfo.id
            },
            endorsement_request_to_club: {
              to_endorser_email: '',
              to_endorser: '',
              from_endorsee: user.user_id,
              type: 'Club',
              status: 'pending',
              comments: comments,
              remarks: '',
              player_career_history: '',
              reg_id: teamId
            },
            endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
          };
          // }
        } else {
          if (personNameEndorsement.length > 0 && personNameEndorsement[0].to_endorser_email === '') {
            notifyCustomWarningMessage('Please select the endorser name.');
            setIsSpinnerLoadingVisible(false);
            return;
          }
          else {
            data = {
              career_history: {
                flag: 'leaguenull',
                club_id: teamId,
                club_name: teamName,
                from_year: fromYear,
                to_year: toYear,
                games_played: gamesPlayed,
                club_goals: goalsScored !== '' ? goalsScored : 0,
                club_assists: assists !== '' ? assists : 0,
                club_passes: passes !== '' ? passes : 0,
                club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
                interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
                takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
                shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
                key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
                dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
                clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
                club_yellow_card: yellowCard !== '' ? yellowCard : 0,
                club_red_card: redCard !== '' ? redCard : 0,
                country_name: countryName,
                league_type: leagueType,
                achievements: keyAchievements,
                summary: summary,
                sport_type: userPersonalData.sport_type,
                players: playerInfo.id
              },
              endorsement_request_to_club: '',
              endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
            };
          }
        }
      } else {
        data = {
          career_history: {
            flag: 'leaguenull',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setPersonNameEndorsement('')
          setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          // setEmailId('')
          setAskforRegistrationEmail('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);
      let data;
      if (checkedEndorsement) {
        if (personNameEndorsement.length > 0 && personNameEndorsement[0].to_endorser_email === '') {
          notifyCustomWarningMessage('Please select the endorser name.');
          setIsSpinnerLoadingVisible(false);
          return;
        }
        else {
          data = {
            career_history: {
              flag: 'team',
              reg_id: uuidv4(),
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type,
              players: playerInfo.id
            },
            // endorsement_request_to_club: !isEmailFieldVisible ? {
            //   to_endorser_email: '',
            //   to_endorser: '',
            //   from_endorsee: user.user_id,
            //   type: 'Club',
            //   status: 'pending',
            //   comments: comments,
            //   remarks: '',
            //   player_career_history: ''
            // } : '',
            endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
          };
        }
      } else {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          // endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          // setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          // setEmailId('')
          setAskforRegistrationEmail('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId === null && leagueName === '') {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);
      let data;
      if (checkedEndorsement) {
        if (personNameEndorsement.length > 0 && personNameEndorsement[0].to_endorser_email === '') {
          notifyCustomWarningMessage('Please select the endorser name.');
          setIsSpinnerLoadingVisible(false);
          return;
        }
        else {
          data = {
            career_history: {
              flag: 'teamleaguenull',
              reg_id: uuidv4(),
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type,
              players: playerInfo.id
            },
            // endorsement_request_to_club: !isEmailFieldVisible ? {
            //   to_endorser_email: '',
            //   to_endorser: '',
            //   from_endorsee: user.user_id,
            //   type: 'Club',
            //   status: 'pending',
            //   comments: comments,
            //   remarks: '',
            //   player_career_history: '',
            //   reg_id: teamId
            // } : '',
            endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
          };
        }
      } else {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          // endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          // setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          // setEmailId('')
          setAskforRegistrationEmail('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else {
      let data;
      if (checkedEndorsement) {
        if (checkedClubEndorsement) {
          // if (personNameEndorsement.length >= 0 && personNameEndorsement[0].to_endorser_email === '') {
          //   notifyCustomWarningMessage('Please select the endorser name.');
          // }
          // else {
          data = {
            career_history: {
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type,
              players: playerInfo.id
            },
            endorsement_request: personNameEndorsement.length > 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
            endorsement_request_to_club: {
              to_endorser_email: '',
              to_endorser: '',
              from_endorsee: user.user_id,
              type: 'Club',
              status: 'pending',
              comments: comments,
              remarks: '',
              player_career_history: '',
              reg_id: teamId
            }
          };
          // }
        } else {
          if (personNameEndorsement.length > 0 && personNameEndorsement[0].to_endorser_email === '') {
            notifyCustomWarningMessage('Please select the endorser name.');
            setIsSpinnerLoadingVisible(false);
            return;
          }
          else {
            data = {
              career_history: {
                club_id: teamId,
                club_name: teamName,
                from_year: fromYear,
                to_year: toYear,
                games_played: gamesPlayed,
                club_goals: goalsScored !== '' ? goalsScored : 0,
                club_assists: assists !== '' ? assists : 0,
                club_passes: passes !== '' ? passes : 0,
                club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
                interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
                takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
                shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
                key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
                dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
                clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
                club_yellow_card: yellowCard !== '' ? yellowCard : 0,
                club_red_card: redCard !== '' ? redCard : 0,
                league_id: leagueId,
                league_name: leagueName,
                country_name: countryName,
                league_type: leagueType,
                achievements: keyAchievements,
                summary: summary,
                sport_type: userPersonalData.sport_type,
                players: playerInfo.id
              },
              endorsement_request: personNameEndorsement.length >= 0 ? personNameEndorsement[0].to_endorser_email !== '' ? personNameEndorsement : '' : '',
              endorsement_request_to_club: ''
            };
          }
        }
      } else {
        data = {
          career_history: {
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: '',
          endorsement_request_to_club: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setComments('')
          // setPersonNameEndorsement('')
          setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          // setEmailId('')
          setAskforRegistrationEmail('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });
    }
  };

  const handleSubmitVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    formData.append("profile_type", 'player');
    formData.append("user_id", user.user_id);
    let response = fetch(`${VIDEOCLIP_URL}/`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setVideoTitle('')
      setVideoTitleError('')
      setVideoUrl('')
      setVideoUrlError('')
      // setIsLoading(true)
      setValidatedVideos(false)
      setShowAddVideosModal(false)
    } else {
      notifyError()
    }
  }

  // fuctions responsible for deleting data from backend

  const handlePlayerClubDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${CLUB_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyRegistrationPersonalInfo();
      const newClubList = playerClub.filter((club) => club.id !== id);
      setPlayerClub(newClubList)
    } else {
      notifyError()
    }
  }

  const handlePlayersEndorsementRequestDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${FOOTBALLPLAYERENDORSEMENTREQUEST_URL}${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      let isClubFlag = 1;
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newList = playerEndorsementRequest.filter((item) => item.id !== id);
      newList.forEach(item => {
        if (item.type === 'Club') {
          isClubFlag = 0;
        }
      })
      if (isClubFlag === 1) {
        setIsEndorsementRequestClubDisabled(false);
        setCheckedClubEndorsement(false);
      }
      if (newList.length === 0) {
        setCheckedEndorsement(false);
        setInputFieldDisabled(false);
      }
      setPlayerEndorsementRequest(newList);
    } else {
      notifyError()
    }
  }

  const handleVideoClipDelete = async (id) => {
    // console.log('Handle Video Delete function called');
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Successfully deleted the data!')
      const newVideoClip = videoClip.filter((clip) => clip.id !== id);
      setVideoClip(newVideoClip)
      // setIsLoading(true)
    } else {
      notifyError()
    }
  }

  // function responsible for updating data in backend

  const handleUpdatePlayer = async (e) => {
    e.preventDefault();
    setValidatedPlayerInfo(true);
    // setIsLoading(true);
    const formData = new FormData();
    if (primaryPosition === '') {
      setPositionError("Primary position can't be empty.")
      return
    }
    else
      formData.append("primary_position", primaryPosition);
    if (prefferedFoot === '') {
      setPreferredFootError("Preferred foot can't be empty.")
      return
    }
    else
      formData.append("preferred_foot", prefferedFoot);
    formData.append("secondary_position", secondaryPosition);
    if (topSpeed !== null)
      formData.append("top_speed", topSpeed);
    // if (teamName === '') {
    //   setClubError("Club Name can't be empty.")
    //   return
    // }
    // else
    //   formData.append("current_club", teamName);
    if (myWorth !== null)
      formData.append("my_worth", myWorth);
    // formData.append("injury_history", injuryHistory);
    formData.append("is_open_for_hiring", checked);
    let response = fetch(`${PLAYER_URL}/${playerId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyRegistrationPersonalInfo()
      setPrimaryPosition('')
      setSecondaryPosition('')
      setTopSpeed(null)
      setPrefferedFoot('')
      // setInjuryHistory('')
      // setTeamName('')
      setMyWorth(null)
      setChecked(false)
      // setDefaultPreferredFoot('')
      // setDefaultPrimaryPosition('')
      // setDefaultSecondaryPosition('')
      // setDefaultTeamName('')
      setValidatedPlayerInfo(false)
      setShowEditGeneralModal(false)
    } else {
      notifyError()
    }
  };

  // const handleUpdatePlayerClubHistory = async (e) => {
  //   // e.preventDefault();
  //   // setIsLoading(true);
  //   setValidatedCareerHistory(true);
  //   const formData = new FormData();
  //   formData.append("sport_type", userPersonalData.sport_type);
  //   formData.append("id", playerClubId);
  //   if (leagueType !== '') {
  //     formData.append("league_type", leagueType);
  //     setTypeError('');
  //   } else {
  //     setTypeError('Type cannot be left empty.');
  //   }
  //   if (leagueType === 'Domestic') {
  //     if (countryName === '') {
  //       setCountryError('Country cannot be left empty.');
  //       return;
  //     }
  //     else {
  //       setCountryError('');
  //       formData.append("country_name", countryName);
  //     }
  //   } else {
  //     setCountryError('');
  //     formData.append("country_name", '');
  //   }
  //   formData.append("league_name", leagueName);
  //   if (leagueId !== null) {
  //     formData.append("league_id", leagueId);
  //   } else {
  //     formData.append("league_id", '');
  //   }
  //   if (teamName !== '') {
  //     // formData.append("club_id", teamId);
  //     formData.append("club_name", teamName);
  //     setTeamError('');
  //   } else {
  //     setTeamError('Team cannot be left empty.');
  //     return;
  //   }
  //   if (teamId !== null) {
  //     formData.append("club_id", teamId);
  //   } else {
  //     formData.append("club_id", 0);
  //   }
  //   if (fromYear !== '') {
  //     formData.append("from_year", fromYear);
  //     setFromYearError('');
  //   } else {
  //     setFromYearError('From year cannot be left empty.');
  //     return;
  //   }
  //   if (toYear !== '') {
  //     formData.append("to_year", toYear);
  //     setToYearError('');
  //   } else {
  //     setToYearError('To year cannot be left empty.');
  //     return;
  //   }
  //   if (gamesPlayed !== '') {
  //     formData.append("games_played", gamesPlayed);
  //     setGamesPlayedError('');
  //   } else {
  //     setGamesPlayedError('Games played cannot be left empty.');
  //     return;
  //   }
  //   if (goalsScored !== '') {
  //     formData.append("club_goals", goalsScored);
  //   } else {
  //     formData.append("club_goals", 0);
  //   }
  //   if (assists !== '') {
  //     formData.append("club_assists", assists);
  //   } else {
  //     formData.append("club_assists", 0);
  //   }
  //   if (passes !== '') {
  //     formData.append("club_passes", passes);
  //   } else {
  //     formData.append("club_passes", 0);
  //   }
  //   if (goalsSaved !== '') {
  //     formData.append("club_saved_goals", goalsSaved);
  //   } else {
  //     formData.append("club_saved_goals", 0);
  //   }
  //   if (interceptionsPerGame !== '') {
  //     formData.append("interceptions_per_game", interceptionsPerGame);
  //   } else {
  //     formData.append("interceptions_per_game", 0);
  //   }
  //   if (tackelsPerGame !== '') {
  //     formData.append("takles_per_game", tackelsPerGame);
  //   } else {
  //     formData.append("takles_per_game", 0);
  //   }
  //   if (shotsPerGame !== '') {
  //     formData.append("shots_per_game", shotsPerGame);
  //   } else {
  //     formData.append("shots_per_game", 0);
  //   }
  //   if (keyPassesPerGame !== '') {
  //     formData.append("key_passes_per_game", keyPassesPerGame);
  //   } else {
  //     formData.append("key_passes_per_game", 0);
  //   }
  //   if (dribblesPerGame !== '') {
  //     formData.append("dribles_completed_per_game", dribblesPerGame);
  //   } else {
  //     formData.append("dribles_completed_per_game", 0);
  //   }
  //   if (cleanSheetsPerGame != '') {
  //     formData.append("clean_sheets_per_game", cleanSheetsPerGame);
  //   } else {
  //     formData.append("clean_sheets_per_game", 0);
  //   }
  //   if (yellowCard !== '') {
  //     formData.append("club_yellow_card", yellowCard);
  //   } else {
  //     formData.append("club_yellow_card", 0);
  //   }
  //   if (redCard !== '') {
  //     formData.append("club_red_card", redCard);
  //   } else {
  //     formData.append("club_red_card", 0);
  //   }
  //   formData.append("achievements", keyAchievements);
  //   formData.append("summary", summary);
  //   formData.append("status", currentRequestStatus);
  //   formData.append("remarks", remarks);
  //   if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'teamleague');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'league');
  //     let response = fetch(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
  //     formData.append("flag", 'team');
  //     formData.append("reg_id", uuidv4());
  //     // formData.append("country_name", userPersonalData.citizenship);
  //     let response = fetch(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     // let response = fetch(`${CLUB_URL}/${playerClubId}/`, {
  //     let response = fetch(`${PLAYERHISTORYUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   }
  // };

  const handleUpdatePlayerClubHistory = async () => {
    // e.preventDefault();
    // setIsLoading(true);
    setValidatedCareerHistory(true);
    // const formData = new FormData();
    // formData.append("sport_type", userPersonalData.sport_type);
    // formData.append("id", playerClubId);
    if (leagueType !== '') {
      // formData.append("league_type", leagueType);
      setTypeError('');
    } else {
      setTypeError('Type cannot be empty.');
    }
    if (leagueType === 'Domestic') {
      if (countryName === '') {
        setCountryError('Country cannot be empty.');
        return;
      }
      else {
        setCountryError('');
        // formData.append("country_name", countryName);
      }
    } else {
      setCountryError('');
      // formData.append("country_name", '');
    }

    if (fromYear !== '') {
      // formData.append("from_year", fromYear);
      setFromYearError('');
    } else {
      setFromYearError('From year cannot be empty.');
      return;
    }
    if (toYear !== '') {
      // formData.append("to_year", toYear);
      setToYearError('');
    } else {
      setToYearError('To year cannot be empty.');
      return;
    }
    if (teamName !== '') {
      // formData.append("games_played", gamesPlayed);
      setTeamError('');
    } else {
      setTeamError('Team name cannot be empty.');
      return;
    }
    // if (leagueName !== '') {
    //   setLeagueError('');
    // } else {
    //   setLeagueError('League name cannot be empty.');
    //   return;
    // }
    if (gamesPlayed !== '') {
      // formData.append("games_played", gamesPlayed);
      setGamesPlayedError('');
    } else {
      setGamesPlayedError('Games played cannot be empty.');
      return;
    }
    // if (checkedEndorsement && isEmailFieldVisible) {
    //   if (emailId === '')
    //     return;
    // }
    setIsSpinnerLoadingVisible(true);
    if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '' && leagueName !== null) {
      // formData.append("flag", 'teamleague');
      // formData.append("reg_id", uuidv4());

      let data;
      if (checkedEndorsement) {
        if (updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email === '') {
          notifyCustomWarningMessage('Please select the endorser name.');
          setIsSpinnerLoadingVisible(false);
          return;
        }
        else {
          data = {
            career_history: {
              flag: 'teamleague',
              reg_id: uuidv4(),
              id: playerClubId,
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type
            },
            // endorsement_request: {
            //   to_endorser_email: emailId,
            //   to_endorser: '',
            //   from_endorsee: user.user_id,
            //   type: 'Club',
            //   status: 'pending',
            //   comments: comments,
            //   remarks: '',
            //   player_career_history: playerClubId
            // },
            endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
          };
        }
      } else {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setUpdatePersonNameEndorsement([])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          setIsEndorsementRequestClubDisabled(false)
          setAskforRegistrationEmail('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          }else{
            notifyCustomErrorMessage(error.response.data[0]);
          }
          setIsSpinnerLoadingVisible(false)
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '' && leagueName !== null) {

      let data;
      if (checkedEndorsement) {
        if (checkedClubEndorsement) {
          data = {
            career_history: {
              flag: 'league',
              id: playerClubId,
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type
            },
            endorsement_request_to_club: {
              to_endorser_email: '',
              to_endorser: '',
              from_endorsee: user.user_id,
              type: 'Club',
              status: 'pending',
              comments: comments,
              remarks: '',
              player_career_history: playerClubId,
              reg_id: teamId
            },
            endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
          };
        } else {
          if (updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email === '') {
            notifyCustomWarningMessage('Please select the endorser name.');
            setIsSpinnerLoadingVisible(false);
            return;
          }
          else {
            data = {
              career_history: {
                flag: 'league',
                id: playerClubId,
                club_id: teamId,
                club_name: teamName,
                from_year: fromYear,
                to_year: toYear,
                games_played: gamesPlayed,
                club_goals: goalsScored !== '' ? goalsScored : 0,
                club_assists: assists !== '' ? assists : 0,
                club_passes: passes !== '' ? passes : 0,
                club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
                interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
                takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
                shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
                key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
                dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
                clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
                club_yellow_card: yellowCard !== '' ? yellowCard : 0,
                club_red_card: redCard !== '' ? redCard : 0,
                league_id: leagueId,
                league_name: leagueName,
                country_name: countryName,
                league_type: leagueType,
                achievements: keyAchievements,
                summary: summary,
                sport_type: userPersonalData.sport_type
              },
              endorsement_request_to_club: '',
              endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
            };
          }
        }
      } else {
        data = {
          career_history: {
            flag: 'league',
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setPersonNameEndorsement('')
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setUpdatePersonNameEndorsement([])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          setIsEndorsementRequestClubDisabled(false)
          setAskforRegistrationEmail('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          } else {
            notifyCustomErrorMessage(error.response.data[0]);
          }
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName === '' && leagueName === null) {

      let data;
      if (checkedEndorsement) {
        if (checkedClubEndorsement) {
          data = {
            career_history: {
              flag: 'leaguenull',
              id: playerClubId,
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type
            },
            endorsement_request_to_club: {
              to_endorser_email: '',
              to_endorser: '',
              from_endorsee: user.user_id,
              type: 'Club',
              status: 'pending',
              comments: comments,
              remarks: '',
              player_career_history: playerClubId,
              reg_id: teamId
            },
            endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
          };
        } else {
          if (updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email === '') {
            notifyCustomWarningMessage('Please select the endorser name.');
            setIsSpinnerLoadingVisible(false);
            return;
          }
          else {
            data = {
              career_history: {
                flag: 'leaguenull',
                id: playerClubId,
                club_id: teamId,
                club_name: teamName,
                from_year: fromYear,
                to_year: toYear,
                games_played: gamesPlayed,
                club_goals: goalsScored !== '' ? goalsScored : 0,
                club_assists: assists !== '' ? assists : 0,
                club_passes: passes !== '' ? passes : 0,
                club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
                interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
                takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
                shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
                key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
                dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
                clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
                club_yellow_card: yellowCard !== '' ? yellowCard : 0,
                club_red_card: redCard !== '' ? redCard : 0,
                country_name: countryName,
                league_type: leagueType,
                achievements: keyAchievements,
                summary: summary,
                sport_type: userPersonalData.sport_type
              },
              endorsement_request_to_club: '',
              endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
            };
          }
        }
      } else {
        data = {
          career_history: {
            flag: 'leaguenull',
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setEmailId('')
          setAskforRegistrationEmail('')
          // setPersonNameEndorsement('')
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setUpdatePersonNameEndorsement([])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          setIsEndorsementRequestClubDisabled(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          } else {
            notifyCustomErrorMessage(error.response.data[0]);
          }
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '' && leagueName !== null) {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);

      let data;
      if (checkedEndorsement) {
        if (updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email === '') {
          notifyCustomWarningMessage('Please select the endorser name.');
          setIsSpinnerLoadingVisible(false);
          return;
        }
        else {
          data = {
            career_history: {
              flag: 'team',
              reg_id: uuidv4(),
              id: playerClubId,
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type
            },
            // endorsement_request: {
            //   to_endorser_email: emailId,
            //   to_endorser: '',
            //   from_endorsee: user.user_id,
            //   type: 'Club',
            //   status: 'pending',
            //   comments: comments,
            //   remarks: '',
            //   player_career_history: playerClubId
            // },
            endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
          };
        }
      } else {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setEmailId('')
          setAskforRegistrationEmail('')
          // setPersonNameEndorsement('')
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setUpdatePersonNameEndorsement([])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          setIsEndorsementRequestClubDisabled(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          } else {
            notifyCustomErrorMessage(error.response.data[0]);
          }
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId === null && leagueName === '' && leagueName === null) {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);

      let data;
      if (checkedEndorsement) {
        if (updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email === '') {
          notifyCustomWarningMessage('Please select the endorser name.');
          setIsSpinnerLoadingVisible(false);
          return;
        }
        else {
          data = {
            career_history: {
              flag: 'teamleaguenull',
              reg_id: uuidv4(),
              id: playerClubId,
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type
            },
            // endorsement_request: {
            //   to_endorser_email: emailId,
            //   to_endorser: '',
            //   from_endorsee: user.user_id,
            //   type: 'Club',
            //   status: 'pending',
            //   comments: comments,
            //   remarks: '',
            //   player_career_history: playerClubId
            // },
            endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
          };
        }
      } else {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          // setEmailId('')
          setAskforRegistrationEmail('')
          // setPersonNameEndorsement('')
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setUpdatePersonNameEndorsement([])
          setCheckedEndorsement(false)
          setCheckedClubEndorsement(false)
          setIsEndorsementRequestClubDisabled(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          } else {
            notifyCustomErrorMessage(error.response.data[0]);
          }
          setIsSpinnerLoadingVisible(false);
        });

    } else {

      let data;
      if (checkedEndorsement) {
        if (checkedClubEndorsement) {
          data = {
            career_history: {
              id: playerClubId,
              club_id: teamId,
              club_name: teamName,
              from_year: fromYear,
              to_year: toYear,
              games_played: gamesPlayed,
              club_goals: goalsScored !== '' ? goalsScored : 0,
              club_assists: assists !== '' ? assists : 0,
              club_passes: passes !== '' ? passes : 0,
              club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
              interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
              takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
              shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
              key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
              dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
              clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
              club_yellow_card: yellowCard !== '' ? yellowCard : 0,
              club_red_card: redCard !== '' ? redCard : 0,
              league_id: leagueId,
              league_name: leagueName,
              country_name: countryName,
              league_type: leagueType,
              achievements: keyAchievements,
              summary: summary,
              sport_type: userPersonalData.sport_type
            },
            endorsement_request_to_club: {
              to_endorser_email: '',
              to_endorser: '',
              from_endorsee: user.user_id,
              type: 'Club',
              status: 'pending',
              comments: comments,
              remarks: '',
              player_career_history: playerClubId,
              reg_id: teamId
            },
            endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
          };
        } else {
          if (updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email === '') {
            notifyCustomWarningMessage('Please select the endorser name.');
            setIsSpinnerLoadingVisible(false);
            return;
          }
          else {
            data = {
              career_history: {
                id: playerClubId,
                club_id: teamId,
                club_name: teamName,
                from_year: fromYear,
                to_year: toYear,
                games_played: gamesPlayed,
                club_goals: goalsScored !== '' ? goalsScored : 0,
                club_assists: assists !== '' ? assists : 0,
                club_passes: passes !== '' ? passes : 0,
                club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
                interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
                takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
                shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
                key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
                dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
                clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
                club_yellow_card: yellowCard !== '' ? yellowCard : 0,
                club_red_card: redCard !== '' ? redCard : 0,
                league_id: leagueId,
                league_name: leagueName,
                country_name: countryName,
                league_type: leagueType,
                achievements: keyAchievements,
                summary: summary,
                sport_type: userPersonalData.sport_type
              },
              endorsement_request_to_club: '',
              endorsement_request: updatePersonNameEndorsement.length > 0 && updatePersonNameEndorsement[0].to_endorser_email !== '' ? updatePersonNameEndorsement : ''
            };
          }
        }
      } else {
        data = {
          career_history: {
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request_to_club: '',
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          // setEmailId('')
          setAskforRegistrationEmail('')
          // setPersonNameEndorsement('')
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setUpdatePersonNameEndorsement([])
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          if (error.response.status === 401) {
            notifyCustomErrorMessage(error.response.data.error);
          } else {
            notifyCustomErrorMessage(error.response.data[0]);
          }
          setIsSpinnerLoadingVisible(false);
        });
    }
  };

  const handleUpdateVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    let response = fetch(`${VIDEOCLIP_URL}/${videoId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setVideoTitle('')
      setVideoUrl('')
      setVideoTitleError('')
      setVideoUrlError('')
      // setIsLoading(true)
      setShowEditVideosModal(false)
    } else {
      notifyError()
    }
  }

  // function responsible for getting user personal information

  const userPersonalInfo = async () => {
    // e.preventDefault();
    // console.log('user Personal Information');
    let response = fetch(`${USER_URL}/${user.user_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    const data = (await response).json()
    // console.log('data:', data)
    // console.log('response:', response)
    const status = (await response).status
    if (status === 200) {
      const personalInfo = (await data)
      // console.log('personalInfo: ', personalInfo)
      setUserPersonalData(personalInfo)
      const videoDetails = (await personalInfo.video_clip)
      setVideoClip(videoDetails)
      const player = (await personalInfo.player[0])
      // console.log('Profile, player Info: ', player)
      const sportProfileType = (await personalInfo.sport_profile_type)
      sportProfileType.map(item => {
        if (item.profile_type === 'Player') {
          if (item.is_active === true)
            setSelectedPlayerSwitchOption(true)
          else
            setSelectedPlayerSwitchOption(false)
        }
        if (item.profile_type === 'Coach') {
          if (item.is_active === true)
            setSelectedCoachSwitchOption(true)
          else
            setSelectedCoachSwitchOption(false)
        }
        if (item.profile_type === 'Agent') {
          if (item.is_active === true)
            setSelectedAgentSwitchOption(true)
          else
            setSelectedAgentSwitchOption(false)
        }
      })
      if (player) {
        setPlayerInfo(player)
        const playerClub = (await player.club)
        // console.log('Profile, club: ', playerClub)
        setPlayerClub(playerClub)
      } else {
        setPlayerInfo('')
        setPlayerClub([])
      }
    } else if (status === 401) {
      notifyAuthenticateError()
    } else {
      notifyError()
    }
  }

  // Handle click event

  const handleClick = () => {
    setIsTeamLOVLoading(false);
    setIsLeagueLOVLoading(false);
  };

  // function to handle lov search filter

  const handleSearchCountryFilter = (value) => {
    const res = optionsCountry.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchCountryLOVData(res);
    setIsCountryLOVLoading(true);
    setCountryName(value);
    if (value === "") {
      setSearchCountryLOVData([]);
      setIsCountryLOVLoading(false);
      setCountryName('');
    }
  }

  const handleSelectCountry = (item) => {
    setCountryName(item.value);
    setIsCountryLOVLoading(false);
  }

  const handleSearchFilter = (value) => {
    const res = optionsTeam.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchTeamLOVData(res);
    setIsTeamLOVLoading(true);
    setTeamName(value);
    if (value === "") {
      setSearchTeamLOVData([]);
      setIsTeamLOVLoading(false);
      setTeamName('');
      setTeamId(null);
    }
  }

  const handleSelectTeam = (item) => {
    setTeamName(item.label);
    setTeamId(item.value);
    setIsTeamLOVLoading(false);
  }

  const handleSearchLeagueFilter = (value) => {
    // setIsTeamLOVLoading(false);
    // console.log('value:', value);
    // console.log('optionsLeague:', optionsLeague);
    const res = optionsLeague.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchLeagueLOVData(res);
    setIsLeagueLOVLoading(true);
    setLeagueName(value);
    if (value === "") {
      setSearchLeagueLOVData([]);
      setIsLeagueLOVLoading(false);
      setLeagueName('');
      setLeagueId(null);
    }
  }

  const handleSelectLeague = (item) => {
    setLeagueName(item.label);
    setLeagueId(item.value);
    setIsLeagueLOVLoading(false);
  }


  // handle on Change functions

  const handleOnChangeRegionType = (e) => {
    if (e) {
      const searchType = e.target.value;
      if (searchType === 'Domestic') {
        getCountries();
        getTeams();
        getLeagues();
        setCountryName('');
        setTeamName('');
        setLeagueName('');
      } else {
        getTeams();
        setTeamName('');
        getLeagues();
        setLeagueName('');
      }
      setLeagueType(searchType);
    } else {
      setLeagueType('');
      setTeamName('');
      setLeagueName('');
    }
  }

  const handleChangeEndorsementType = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...personNameEndorsement]
    onchangeVal[i]['type'] = e.target.value
    onchangeVal[i]['from_endorsee'] = user.user_id;
    onchangeVal[i]['status'] = 'pending';
    // onchangeVal[i]['player_career_history'] = '';
    setPersonNameEndorsement(onchangeVal)
    // console.log('onchangeVal', onchangeVal)
  }

  const handleChangeEditEndorsementType = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...updatePersonNameEndorsement]
    onchangeVal[i]['type'] = e.target.value
    onchangeVal[i]['from_endorsee'] = user.user_id;
    onchangeVal[i]['status'] = 'pending';
    // onchangeVal[i]['player_career_history'] = '';
    setUpdatePersonNameEndorsement(onchangeVal)
    // console.log('onchangeVal', onchangeVal)
  }

  const handleChangeEndorsement = (e, i) => {
    const { name, value } = e.target
    const onchangeVal = [...personNameEndorsement]
    onchangeVal[i][name] = value
    setPersonNameEndorsement(onchangeVal)
    // console.log('onchangeVal', onchangeVal)
  }

  const handleChangeEditEndorsement = (e, i) => {
    const { name, value } = e.target
    const onchangeVal = [...updatePersonNameEndorsement]
    onchangeVal[i][name] = value
    setUpdatePersonNameEndorsement(onchangeVal)
    // console.log('onchangeVal', onchangeVal)
  }

  const handleOnClickSearchEndorsementFilter = async (type, i) => {
    // setValidatedCareerHistory(true);
    let COMPLETE_URL;
    let apiEndPoint;
    if (type === 'Player') {
      setSearchUserProfileType(type);
      if (personNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(personNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'user__full_name=' + personNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHPLAYER_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
      setShowModalEndorsement(true);
    } else if (type === 'Coach') {
      setSearchUserProfileType(type);
      if (personNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(personNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'user__full_name=' + personNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHCOACH_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
      setShowModalEndorsement(true);
    } else if (type === 'Agent') {
      setSearchUserProfileType(type);
      if (personNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(personNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'user__full_name=' + personNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHAGENT_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHAGENT_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
      setShowModalEndorsement(true);
    } else {
      // console.log('my profile type', type);
      setSearchUserProfileType(type);
      if (personNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(personNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'full_name=' + personNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
        // } else {
        //   apiEndPoint = 'full_name=user';
        //   COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', await data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.id === item2.to_endorser.id)
          );
          setAllSportPersonData(filteredArray);
        } else {
          setAllSportPersonData([]);
        }
      } else {
        setAllSportPersonData([]);
      }
      setShowModalEndorsementAll(true);
    }
    setShowAddCareerHistoryModal(false);
    // setShowModalEndorsement(true);
  }

  const handleOnClickSearchEditEndorsementFilter = async (type, i) => {
    // setValidatedCareerHistory(true);
    let COMPLETE_URL;
    let apiEndPoint;
    if (type === 'Player') {
      setSearchUserProfileType(type);
      if (updatePersonNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(updatePersonNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'user__full_name=' + updatePersonNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHPLAYER_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
      setShowEditEndorsementModal(true);
    } else if (type === 'Coach') {
      setSearchUserProfileType(type);
      if (updatePersonNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(updatePersonNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'user__full_name=' + updatePersonNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHCOACH_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
      setShowEditEndorsementModal(true);
    } else if (type === 'Agent') {
      setSearchUserProfileType(type);
      if (updatePersonNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(updatePersonNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'user__full_name=' + updatePersonNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHAGENT_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHAGENT_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
      setShowEditEndorsementModal(true);
    } else {
      // console.log('my profile type', type);
      setSearchUserProfileType(type);
      if (updatePersonNameEndorsement[i].to_endorser_email !== '') {
        // console.log('personNameEndorsement:', personNameEndorsement[i]);
        setSearchUserInfo(updatePersonNameEndorsement[i].to_endorser_email);
        apiEndPoint = 'full_name=' + updatePersonNameEndorsement[i].to_endorser_email;
        COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
        // } else {
        //   apiEndPoint = 'full_name=user';
        //   COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', await data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.id === item2.to_endorser.id)
          );
          setAllSportPersonData(filteredArray);
        } else {
          setAllSportPersonData([]);
        }
      } else {
        setAllSportPersonData([]);
      }
      setShowEditAllEndorsementModal(true);
    }
    setShowEditCareerHistoryModal(false);
    // setShowModalEndorsement(true);
  }

  const handleOnClickSportPersonEndorsement = (data, i) => {
    // const { name, value } = e.target
    // const name = 'text';
    const onchangeVal = [...personNameEndorsement]
    // onchangeVal[i]['to_endorser_email'] = data.user.email;
    onchangeVal[i]['to_endorser_email'] = data.user.first_name + ' ' + data.user.last_name;
    onchangeVal[i]['to_endorser'] = data.user.id;
    onchangeVal[i]['from_endorsee'] = user.user_id;
    // onchangeVal[i]['type'] = personId;
    onchangeVal[i]['status'] = 'pending';
    // onchangeVal[i]['agent_players_coaches_under_me'] = agentPlayersAndCoachesId;
    setPersonNameEndorsement(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setShowModalEndorsement(false);
    setSearchUserInfo('');
    setShowAddCareerHistoryModal(true);
  }

  const handleOnClickEditSportPersonEndorsement = (data, i) => {
    // const { name, value } = e.target
    // const name = 'text';
    console.log("i", i);
    const onchangeVal = [...updatePersonNameEndorsement]
    // onchangeVal[i]['to_endorser_email'] = data.user.email;
    onchangeVal[i]['to_endorser_email'] = data.user.first_name + ' ' + data.user.last_name;
    onchangeVal[i]['to_endorser'] = data.user.id;
    onchangeVal[i]['from_endorsee'] = user.user_id;
    // onchangeVal[i]['type'] = personId;
    onchangeVal[i]['status'] = 'pending';
    onchangeVal[i]['player_career_history'] = playerClubId;
    setUpdatePersonNameEndorsement(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setSearchUserInfo('');
    setShowEditEndorsementModal(false);
    setShowEditCareerHistoryModal(true);
  }

  const handleOnClickAllSportPersonEndorsement = (data, i) => {
    // const { name, value } = e.target
    // const name = 'text';
    let currentProfileType;
    data.sport_profile_type.forEach(item => {
      if ((item.is_active === true) && (item.status === 'Current')) {
        currentProfileType = item.profile_type;
      }
    })
    const onchangeVal = [...personNameEndorsement]
    // onchangeVal[i]['to_endorser_email'] = data.email;
    onchangeVal[i]['to_endorser_email'] = data.first_name + ' ' + data.last_name;
    onchangeVal[i]['to_endorser'] = data.id;
    onchangeVal[i]['from_endorsee'] = user.user_id;
    onchangeVal[i]['type'] = currentProfileType;
    onchangeVal[i]['status'] = 'pending';
    // onchangeVal[i]['agent_players_coaches_under_me'] = agentPlayersAndCoachesId;
    setPersonNameEndorsement(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setSearchUserInfo('');
    setShowModalEndorsementAll(false);
    setShowAddCareerHistoryModal(true);
  }

  const handleOnClickEditAllSportPersonEndorsement = (data, i) => {
    // const { name, value } = e.target
    // const name = 'text';
    let currentProfileType;
    data.sport_profile_type.forEach(item => {
      if ((item.is_active === true) && (item.status === 'Current')) {
        currentProfileType = item.profile_type;
      }
    })
    const onchangeVal = [...updatePersonNameEndorsement]
    // onchangeVal[i]['to_endorser_email'] = data.email;
    onchangeVal[i]['to_endorser_email'] = data.first_name + ' ' + data.last_name;
    onchangeVal[i]['to_endorser'] = data.id;
    onchangeVal[i]['from_endorsee'] = user.user_id;
    onchangeVal[i]['type'] = currentProfileType;
    onchangeVal[i]['status'] = 'pending';
    onchangeVal[i]['player_career_history'] = playerClubId;
    setUpdatePersonNameEndorsement(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setSearchUserInfo('');
    setShowEditAllEndorsementModal(false);
    setShowEditCareerHistoryModal(true);
  }

  const handleAddFieldEndorsement = () => {
    setPersonNameEndorsement([...personNameEndorsement, { to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
  }

  const handleAddFieldEditEndorsement = () => {
    setUpdatePersonNameEndorsement([...updatePersonNameEndorsement, { to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
    // console.log('after setUpdatePersonNameEndorsement:', updatePersonNameEndorsement);
  }

  const handleDeleteEndorsement = (i) => {
    const deleteVal = [...personNameEndorsement]
    deleteVal.splice(i, 1)
    setPersonNameEndorsement(deleteVal)
  }

  const handleDeleteEditEndorsement = (i) => {
    const deleteVal = [...updatePersonNameEndorsement]
    deleteVal.splice(i, 1)
    setUpdatePersonNameEndorsement(deleteVal)
  }

  const onChangeHandleComments = (e) => {
    // const { name, value } = e.target
    // const name = 'text';
    setComments(e.target.value)
    const onchangeVal = [...personNameEndorsement]
    onchangeVal.map(item => {
      item.comments = e.target.value
    })
    // console.log('onchangeVal', onchangeVal)
    setPersonNameEndorsement(onchangeVal);
  }

  const onChangeHandleEditComments = (e) => {
    // const { name, value } = e.target
    // const name = 'text';
    setComments(e.target.value)
    const onchangeVal = [...updatePersonNameEndorsement]
    onchangeVal.map(item => {
      item.comments = e.target.value
    })
    // console.log('onchangeVal', onchangeVal)
    setUpdatePersonNameEndorsement(onchangeVal);
  }

  const handleCheckedEndorsement = () => {
    setCheckedEndorsement(!checkedEndorsement);
    if (!checkedEndorsement) {
      if (teamId !== null)
        getInstitution(teamId);
      else
        setIsEmailFieldVisible(true)
    } else {
      setIsEmailFieldVisible(false)
    }
  }

  const handleCheckedClubEndorsement = () => {
    setCheckedClubEndorsement(!checkedClubEndorsement);
  }

  // const handleOnChangeSearchUserInfo = async (e) => {
  //   setSearchUserInfo(e.target.value);
  //   let COMPLETE_URL;
  //   let apiEndPoint;
  //   if (searchUserProfileType === '') {
  //     if (e.target.value !== '') {
  //       apiEndPoint = 'full_name=' + e.target.value;
  //       COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
  //     } else {
  //       apiEndPoint = 'full_name=user';
  //       COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
  //     }
  //     let response = fetch(`${COMPLETE_URL}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     // console.log('response:', response)
  //     const data = (await response).json()
  //     // console.log('data:', data)
  //     const status = (await response).status
  //     // console.log('status:', status)
  //     if (status === 200) {
  //       setAllSportPersonData(await data);
  //     }
  //   } else if (searchUserProfileType === 'Player') {
  //     if (e.target.value !== '') {
  //       apiEndPoint = 'user__full_name=' + e.target.value;
  //       COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
  //     } else {
  //       COMPLETE_URL = SEARCHPLAYER_URL
  //     }
  //     let response = fetch(`${COMPLETE_URL}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     // console.log('response:', response)
  //     const data = (await response).json()
  //     // console.log('data:', data)
  //     const status = (await response).status
  //     // console.log('status:', status)
  //     if (status === 200) {
  //       setSportPersonData(await data);
  //     }
  //   } else if (searchUserProfileType === 'Coach') {
  //     if (e.target.value !== '') {
  //       apiEndPoint = 'user__full_name=' + e.target.value;
  //       COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
  //     } else {
  //       COMPLETE_URL = SEARCHCOACH_URL
  //     }
  //     let response = fetch(`${COMPLETE_URL}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     // console.log('response:', response)
  //     const data = (await response).json()
  //     // console.log('data:', data)
  //     const status = (await response).status
  //     // console.log('status:', status)
  //     if (status === 200) {
  //       setSportPersonData(await data);
  //     }
  //   } else if (searchUserProfileType === 'Agent') {
  //     if (e.target.value !== '') {
  //       apiEndPoint = 'user__full_name=' + e.target.value;
  //       COMPLETE_URL = SEARCHAGENT_URL + apiEndPoint;
  //     } else {
  //       COMPLETE_URL = SEARCHAGENT_URL
  //     }
  //     let response = fetch(`${COMPLETE_URL}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     // console.log('response:', response)
  //     const data = (await response).json()
  //     // console.log('data:', data)
  //     const status = (await response).status
  //     // console.log('status:', status)
  //     if (status === 200) {
  //       setSportPersonData(await data);
  //     }
  //   }
  // }

  const handleOnClickSearchUserInfo = async () => {
    // setSearchUserInfo(e.target.value);
    let COMPLETE_URL;
    let apiEndPoint;
    if (searchUserProfileType === '') {
      if (searchUserInfo !== '') {
        apiEndPoint = 'full_name=' + searchUserInfo;
        COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
        // } else {
        //   apiEndPoint = 'full_name=user';
        //   COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.id === item2.to_endorser.id)
          );
          setAllSportPersonData(filteredArray);
        } else {
          setAllSportPersonData([]);
        }
      } else {
        setAllSportPersonData([]);
      }
    } else if (searchUserProfileType === 'Player') {
      console.log('We are inside of Player block');
      if (searchUserInfo !== '') {
        apiEndPoint = 'user__full_name=' + searchUserInfo;
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHPLAYER_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
    } else if (searchUserProfileType === 'Coach') {
      if (searchUserInfo !== '') {
        apiEndPoint = 'user__full_name=' + searchUserInfo;
        COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHCOACH_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
    } else if (searchUserProfileType === 'Agent') {
      if (searchUserInfo !== '') {
        apiEndPoint = 'user__full_name=' + searchUserInfo;
        COMPLETE_URL = SEARCHAGENT_URL + apiEndPoint;
        // } else {
        //   COMPLETE_URL = SEARCHAGENT_URL
        // }
        let response = fetch(`${COMPLETE_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
          const filteredData = await data;
          // console.log('list of filtered user:', filteredData);
          // console.log('list of endorsement user:', playerEndorsementRequest);
          const filteredArray = filteredData.filter(item1 =>
            !playerEndorsementRequest.some(item2 => item1.user.id === item2.to_endorser.id)
          );
          setSportPersonData(filteredArray);
        } else {
          setSportPersonData([]);
        }
      } else {
        setSportPersonData([]);
      }
    }
  }

  // handle on Close Modal

  const handleAddGenralModalClose = () => {
    setShowAddGeneralModal(false);
  }

  const handleEditGenralModalClose = () => {
    setShowEditGeneralModal(false);
  }

  const handleAddCareerHistoryModalClose = () => {
    setShowAddCareerHistoryModal(false);
    setTeamName('')
    setTeamId(null)
    setGamesPlayed('')
    setFromYear('')
    setToYear('')
    setAssists('')
    setGoalsSaved('')
    setGoalsScored('')
    setCleanSheetsPerGame('')
    setShotsPerGame('')
    setDribblesPerGame('')
    setInterceptionsPerGame('')
    setKeyPassesPerGame('')
    setLeagueName('')
    setLeagueId(null)
    setLeagueType('')
    setPasses('')
    setRedCard('')
    setYellowCard('')
    setCurrentRequestStatus('')
    setRemarks('')
    setTackelsPerGame('')
    setCountryName('')
    setKeyAchievements('')
    getLeagues()
    getTeams()
    // setPersonNameEndorsement('')
    setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
    setCheckedEndorsement(false)
    setCheckedClubEndorsement(false)
    // setEmailId('')
    setAskforRegistrationEmail('')
    setValidatedCareerHistory(false)
    setFromYearError('')
    setToYearError('')
    setTypeError('')
    setGamesPlayedError('')
    setTeamError('')
    setLeagueError('')
  }

  const handleEditCareerHistoryModalClose = () => {
    setShowEditCareerHistoryModal(false);
    setTeamName('')
    setTeamId(null)
    setGamesPlayed('')
    setFromYear('')
    setToYear('')
    setAssists('')
    setGoalsSaved('')
    setGoalsScored('')
    setCleanSheetsPerGame('')
    setShotsPerGame('')
    setDribblesPerGame('')
    setInterceptionsPerGame('')
    setKeyPassesPerGame('')
    setLeagueName('')
    setLeagueId(null)
    setLeagueType('')
    setPasses('')
    setRedCard('')
    setYellowCard('')
    setCurrentRequestStatus('')
    setRemarks('')
    setTackelsPerGame('')
    setCountryName('')
    setKeyAchievements('')
    getLeagues()
    getTeams()
    // setPersonNameEndorsement('')
    // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
    setUpdatePersonNameEndorsement([])
    setCheckedEndorsement(false)
    setCheckedClubEndorsement(false)
    setIsEndorsementRequestClubDisabled(false)
    // setEmailId('')
    setAskforRegistrationEmail('')
    setIsEmailFieldVisible(false)
    setValidatedCareerHistory(false)
  }

  const handleAddVideosModalClose = () => {
    setShowAddVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
  }

  const handleEditVideosModalClose = () => {
    setShowEditVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
  }

  const handleShowEndorsementModalClose = () => {
    setShowModalEndorsement(false);
    setShowAddCareerHistoryModal(true);
    setSearchUserInfo('');
    setSearchUserProfileType('');
  }

  const handleShowEndorsementModalAllClose = () => {
    setShowModalEndorsementAll(false);
    setShowAddCareerHistoryModal(true);
    setSearchUserInfo('');
    setSearchUserProfileType('');
  }

  const handleShowAskForRegistrationModal = () => {
    setShowModalEndorsement(false);
    setShowModalEndorsementAll(false);
    setShowModalAskForRegistration(true);
    setShowAddCareerHistoryModal(false);
    setSearchUserInfo('');
    setSearchUserProfileType('');
  }

  const handleShowAskForRegistrationModalClose = () => {
    setShowModalAskForRegistration(false);
    setShowModalEndorsement(false);
    setShowAddCareerHistoryModal(true);
    setSearchUserInfo('');
    setSearchUserProfileType('');
  }

  const handleShowEditEndorsementModalClose = () => {
    setShowEditEndorsementModal(false);
    setShowEditCareerHistoryModal(true);
    setSearchUserInfo('');
    setSearchUserProfileType('');
  }

  const handleShowEditAllEndorsementModalClose = () => {
    setShowEditAllEndorsementModal(false);
    setShowEditCareerHistoryModal(true);
    setSearchUserInfo('');
    setSearchUserProfileType('');
  }

  // useEffect event triggered on page load

  useEffect(() => {
    userPersonalInfo();

    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        setIsTeamLOVLoading(false);
        setIsLeagueLOVLoading(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showAddGeneralModal, showAddCareerHistoryModal, showAddVideosModal, showEditGeneralModal, showEditCareerHistoryModal, showEditVideosModal]);

  // const playerClubList = playerClub.map((club) => {
  //   let currentStatus;
  //   if (club.status === 'Submitted') {
  //     currentStatus = 'Waiting for Approval';
  //     return (
  //       <tr key={club.id}>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faCheck}
  //             color='#0f0'
  //           />
  //         </td>
  //         <td>{club.club_name}</td>
  //         <td>{club.from_year}</td>
  //         <td>{club.to_year}</td>
  //         <td>{club.league_name}</td>
  //         <td>{club.games_played}</td>
  //         <td>{club.club_goals}</td>
  //         <td>{club.club_saved_goals}</td>
  //         <td>{currentStatus}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faTrashCan}
  //             color='#ff001b5c'
  //           />
  //         </td>
  //       </tr>
  //     );
  //   } else if (club.status === 'Draft') {
  //     currentStatus = 'Draft';
  //   } else if (club.status === 'Approved') {
  //     currentStatus = 'Approved';
  //     return (
  //       <tr key={club.id}>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faCheckDouble}
  //             color='#0f0'
  //           />
  //         </td>
  //         <td>{club.club_name}</td>
  //         <td>{club.from_year}</td>
  //         <td>{club.to_year}</td>
  //         <td>{club.league_name}</td>
  //         <td>{club.games_played}</td>
  //         <td>{club.club_goals}</td>
  //         <td>{club.club_saved_goals}</td>
  //         <td>{currentStatus}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faTrashCan}
  //             color='#ff001b5c'
  //           />
  //         </td>
  //       </tr>
  //     );
  //   } else if (club.status === 'Rejected') {
  //     currentStatus = 'Rejected'
  //   }
  //   return (
  //     <tr key={club.id}>
  //       <td>
  //         <FontAwesomeIcon
  //           icon={faEdit}
  //           color='#2222e785'
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => {
  //             getPlayerClubInfo(club.id);
  //             setShowEditCareerHistoryModal(true);
  //           }}
  //         />
  //       </td>
  //       <td>{club.club_name}</td>
  //       <td>{club.from_year}</td>
  //       <td>{club.to_year}</td>
  //       <td>{club.league_name}</td>
  //       <td>{club.games_played}</td>
  //       <td>{club.club_goals}</td>
  //       <td>{club.club_saved_goals}</td>
  //       <td>{currentStatus}</td>
  //       <td>
  //         <FontAwesomeIcon
  //           icon={faTrashCan}
  //           color='red'
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => handlePlayerClubDelete(club.id)}
  //         />
  //       </td>
  //     </tr>
  //   );
  // })

  const playerClubList = playerClub.map((club) => {
    return (
      <tr key={club.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getPlayerClubInfo(club.id);
              setShowEditCareerHistoryModal(true);
            }}
          />
        </td>
        <td>{club.club_name}</td>
        <td>{club.from_year}</td>
        <td>{club.to_year}</td>
        <td>{club.league_name}</td>
        <td>{club.games_played}</td>
        <td>{club.club_goals}</td>
        <td>{club.club_saved_goals}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handlePlayerClubDelete(club.id)}
          />
        </td>
      </tr>
    );
  })

  const personalAchievementList = playerClub.map((club) => {
    const parsedData = club.achievements !== null && club.achievements !== '' ? club.achievements.split('\n') : null;
    if (club.achievements !== null && club.achievements !== '')
      return (
        <tr key={club.id}>
          {/* <td>{club.achievements}</td> */}
          <td>{club.from_year}</td>
          <td>{club.to_year}</td>
          <td>
            {parsedData && parsedData.length > 0 &&
              <ul>
                {parsedData.map((item, index) => (
                  <li key={index} className='card-text'>{item}</li>
                ))}
              </ul>
            }
          </td>
        </tr>
      );
  })

  const videoClipList = videoClip.map((clip) => {
    if (clip.profile_type === 'player') {
      return (
        <tr key={clip.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                getVideoClipInfo(clip.id);
                setShowEditVideosModal(true);
              }}
            />
          </td>
          <td>{clip.title}</td>
          <td>
            <a href={'https://' + clip.clip_url} target="_blank" rel="noopener noreferrer">
              {clip.clip_url}
            </a>
          </td>
          <td>
            <FontAwesomeIcon
              icon={faTrashCan}
              color='red'
              style={{ cursor: 'pointer' }}
              onClick={() => handleVideoClipDelete(clip.id)}
            />
          </td>
        </tr>
      );
    }
  })

  const sportPersonEndorsementList = sportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickSportPersonEndorsement(data, personNameEndorsement.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.user.first_name} {data.user.last_name}
          </span>
          <span>
            {data.user.email}
          </span>
        </div>
        <div>
          {data.user.sport_type}
        </div>
      </li>
    );
  })

  const editSportPersonEndorsementList = sportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickEditSportPersonEndorsement(data, updatePersonNameEndorsement.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.user.first_name} {data.user.last_name}
          </span>
          <span>
            {data.user.email}
          </span>
        </div>
        <div>
          {data.user.sport_type}
        </div>
      </li>
    );
  })

  const allSportPersonEndorsementList = allSportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickAllSportPersonEndorsement(data, personNameEndorsement.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.first_name} {data.last_name}
          </span>
          <span>
            {data.email}
          </span>
        </div>
        <div>
          {data.sport_type} -
          {data.sport_profile_type.map(item => (
            item.is_active === true && item.status === 'Current' &&
            <span key={item.id} style={{ marginLeft: 3 }}>
              {item.profile_type}
            </span>
          ))}
        </div>
      </li>
    );
  })

  const editAllSportPersonEndorsementList = allSportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickEditAllSportPersonEndorsement(data, updatePersonNameEndorsement.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.first_name} {data.last_name}
          </span>
          <span>
            {data.email}
          </span>
        </div>
        <div>
          {data.sport_type} -
          {data.sport_profile_type.map(item => (
            item.is_active === true && item.status === 'Current' &&
            <span key={item.id} style={{ marginLeft: 3 }}>
              {item.profile_type}
            </span>
          ))}
        </div>
      </li>
    );
  })

  const playersEndorsementRequestList = playerEndorsementRequest.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          {item.to_endorser.first_name === '' && item.to_endorser.last_name === '' ?
            item.to_endorser.email : item.to_endorser.first_name + ' ' + item.to_endorser.last_name
          }
        </td>
        <td>{item.type}</td>
        <td>{item.status}</td>
        <td>{item.remarks}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handlePlayersEndorsementRequestDelete(item.id)}
          />
        </td>
      </tr>
    );
  })

  return (
    <>

      <Modal show={showModalEndorsementAll} onHide={handleShowEndorsementModalAllClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-1">
            <Form.Group as={Col}>
              <Stack direction="horizontal" gap={2}>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the user name or email"
                  value={searchUserInfo}
                  // onChange={handleOnChangeSearchUserInfo}
                  onChange={(e) => setSearchUserInfo(e.target.value)}
                />
                <Button variant="primary" size="lg" onClick={handleOnClickSearchUserInfo}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    // color='green'
                    // size = '2x'
                    style={{ cursor: 'pointer', marginRight: 5 }}
                  />
                </Button>
              </Stack>
            </Form.Group>
          </Form>
          <ul className='content-list' style={{ padding: 10 }}>
            {allSportPersonData && allSportPersonData.length > 0 ? allSportPersonEndorsementList : 'No user found'}
          </ul>
          <Alert variant='secondary'>
            If you do not found the user send an invitation asking for registration.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col className="d-flex justify-content-start">
              {/* Button aligned to the left */}
              <Button variant="primary" onClick={handleShowAskForRegistrationModal}>
                Ask for registration
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* Button aligned to the right */}
              <Button variant="secondary" onClick={handleShowEndorsementModalAllClose}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEndorsement} onHide={handleShowEndorsementModalClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-1">
            <Form.Group as={Col}>
              <Stack direction="horizontal" gap={2}>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the user name or email"
                  value={searchUserInfo}
                  // onChange={handleOnChangeSearchUserInfo}
                  onChange={(e) => setSearchUserInfo(e.target.value)}
                />
                <Button variant="primary" size="lg" onClick={handleOnClickSearchUserInfo}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    // color='green'
                    // size = '2x'
                    style={{ cursor: 'pointer', marginRight: 5 }}
                  />
                </Button>
              </Stack>
            </Form.Group>
          </Form>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? sportPersonEndorsementList : 'No user found'}
          </ul>
          <Alert variant='secondary'>
            If you do not find the user send an invitation link asking for registration.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col className="d-flex justify-content-start">
              {/* Button aligned to the left */}
              <Button variant="primary" onClick={handleShowAskForRegistrationModal}>
                Ask for registration
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* Button aligned to the right */}
              <Button variant="secondary" onClick={handleShowEndorsementModalClose}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditAllEndorsementModal} onHide={handleShowEditAllEndorsementModalClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-1">
            <Form.Group as={Col}>
              <Stack direction="horizontal" gap={2}>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the user name or email"
                  value={searchUserInfo}
                  // onChange={handleOnChangeSearchUserInfo}
                  onChange={(e) => setSearchUserInfo(e.target.value)}
                />
                <Button variant="primary" size="lg" onClick={handleOnClickSearchUserInfo}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    // color='green'
                    // size = '2x'
                    style={{ cursor: 'pointer', marginRight: 5 }}
                  />
                </Button>
              </Stack>
            </Form.Group>
          </Form>
          <ul className='content-list' style={{ padding: 10 }}>
            {allSportPersonData && allSportPersonData.length > 0 ? editAllSportPersonEndorsementList : 'No user found'}
          </ul>
          <Alert variant='secondary'>
            If you do not found the user send an invitation asking for registration.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col className="d-flex justify-content-start">
              {/* Button aligned to the left */}
              <Button variant="primary" onClick={handleShowAskForRegistrationModal}>
                Ask for registration
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* Button aligned to the right */}
              <Button variant="secondary" onClick={handleShowEditAllEndorsementModalClose}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditEndorsementModal} onHide={handleShowEditEndorsementModalClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-1">
            <Form.Group as={Col}>
              <Stack direction="horizontal" gap={2}>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the user name or email"
                  value={searchUserInfo}
                  // onChange={handleOnChangeSearchUserInfo}
                  onChange={(e) => setSearchUserInfo(e.target.value)}
                />
                <Button variant="primary" size="lg" onClick={handleOnClickSearchUserInfo}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    // color='green'
                    // size = '2x'
                    style={{ cursor: 'pointer', marginRight: 5 }}
                  />
                </Button>
              </Stack>
            </Form.Group>
          </Form>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? editSportPersonEndorsementList : 'No user found'}
          </ul>
          <Alert variant='secondary'>
            If you do not find the user send an invitation link asking for registration.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col className="d-flex justify-content-start">
              {/* Button aligned to the left */}
              <Button variant="primary" onClick={handleShowAskForRegistrationModal}>
                Ask for registration
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* Button aligned to the right */}
              <Button variant="secondary" onClick={handleShowEditEndorsementModalClose}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalAskForRegistration} onHide={handleShowAskForRegistrationModalClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Ask for registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible &&
            <div className='text-center'>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            </div>
          }
          <Form>
            <Form.Group as={Col}>
              <Form.Label className='custom-label'>Email
                <span className='text-danger'> * </span>
              </Form.Label>
              <Form.Control
                className='input'
                type="email"
                size='lg'
                placeholder="Enter the email for sending invitation link"
                value={askforRegistrationEmail}
                onChange={(e) => setAskforRegistrationEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Email cannot be empty.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowAskForRegistrationModalClose} disabled={inputFieldDisabled}>Close</Button>
          <Button variant="primary" onClick={handleOnClickSendInvitation} disabled={inputFieldDisabled}>Send Invitation</Button>
        </Modal.Footer>
      </Modal >

      <Modal show={showAddGeneralModal} onHide={handleAddGenralModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add player informantion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedPlayerInfo}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Primary Position
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={primaryPosition}
                  onChange={(e) => setPrimaryPosition(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position, index) => (
                    <option value={position.value} key={index}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {positionError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Secondary Position</Form.Label>
                <Form.Select
                  size='lg'
                  value={secondaryPosition}
                  onChange={(e) => setSecondaryPosition(e.target.value)}
                // style={{ fontSize: 11, padding: 10 }}
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position, index) => (
                    <option value={position.value} key={index}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Preferred Foot
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={prefferedFoot}
                  onChange={(e) => setPrefferedFoot(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPreferredFoot.map((foot, index) => (
                    <option value={foot.value} key={index}>
                      {foot.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {preferredFootError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Top Speed</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter the top speed of player"
                      value={topSpeed}
                      onChange={(e) => setTopSpeed(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in km/hr
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>My Worth</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter the worth price"
                      value={myWorth}
                      onChange={(e) => setMyWorth(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in millions
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Open for Hiring</Form.Label>
                <Form.Check
                  type="checkbox"
                  size='lg'
                  label="Is open"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddGenralModalClose}>Close</Button>
          <Button variant="primary" onClick={handleAddPlayerSubmit}>Save Changes</Button>
        </Modal.Footer>
      </Modal >

      <Modal show={showEditGeneralModal} onHide={handleEditGenralModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit player informantion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedPlayerInfo}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Primary Position
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={primaryPosition}
                  onChange={(e) => setPrimaryPosition(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position, index) => (
                    <option value={position.value} key={index}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {positionError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Secondary Position</Form.Label>
                <Form.Select
                  size='lg'
                  value={secondaryPosition}
                  onChange={(e) => setSecondaryPosition(e.target.value)}
                // style={{ fontSize: 11, padding: 10 }}
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position, index) => (
                    <option value={position.value} key={index}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Preferred Foot
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={prefferedFoot}
                  onChange={(e) => setPrefferedFoot(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPreferredFoot.map((foot, index) => (
                    <option value={foot.value} key={index}>
                      {foot.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {preferredFootError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Top Speed</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter the top speed of player"
                      value={topSpeed}
                      onChange={(e) => setTopSpeed(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in km/hr
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>My Worth</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter your worth price"
                      value={myWorth}
                      onChange={(e) => setMyWorth(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in millions
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Open for Hiring</Form.Label>
                <Form.Check
                  type="checkbox"
                  size='lg'
                  label="Is open"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditGenralModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdatePlayer}>Save Changes</Button>
        </Modal.Footer>
      </Modal >

      <Modal show={showAddCareerHistoryModal} onHide={handleAddCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Career History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedCareerHistory}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2013"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>Type
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={leagueType}
                    // onChange={(e) => setLeagueType(e.target.value)}
                    onChange={handleOnChangeRegionType}
                    // style={{ fontSize: 11, padding: 10 }}
                    onClick={handleClick}
                    required
                  >
                    <option value=''>Open this select menu</option>
                    <option value="International">International</option>
                    <option value="Domestic">Domestic</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {typeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Summary</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the summary of your career"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {leagueType === 'Domestic' &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Country Name
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    {/* <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Select the name of a country"
                      value={countryName}
                      onChange={e => handleSearchCountryFilter(e.target.value)}
                      onClick={handleClick}
                      required
                    />
                    {isCountryLOVloading &&
                      <div className='lov-content mt-1'>
                        <ul className='lov-list' style={{ padding: 5 }}>
                          {
                            searchCountryLOVData.length > 0 ?
                              searchCountryLOVData.map((item) => (
                                <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                                  {item.label}
                                </li>
                              ))

                              : <li className='lov-list-element' onClick={() => { setCountryName(''); setIsCountryLOVLoading(false); }}>
                                No data found. Please select a valid country.
                              </li>
                          }
                        </ul>
                      </div>
                    } */}
                    <Form.Select
                      size='lg'
                      value={countryName}
                      onChange={(e) => setCountryName(e.target.value)}
                      required
                    >
                      <option value="">Open this select menu</option>
                      {optionsCountry.map((country) => (
                        <option value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {countryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Club Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a club"
                    value={teamName}
                    onChange={e => handleSearchFilter(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  {isTeamLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchTeamLOVData.length > 0 ?
                            searchTeamLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectTeam(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsTeamLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {teamError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>League
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a league"
                    value={leagueName}
                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                    onClick={handleClick}
                  />
                  {isLeagueLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchLeagueLOVData.length > 0 ?
                            searchLeagueLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsLeagueLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {leagueError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Games Played
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={gamesPlayed}
                    onChange={(e) => setGamesPlayed(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {gamesPlayedError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Scored</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals scored"
                    value={goalsScored}
                    onChange={(e) => setGoalsScored(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Assists</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of assists"
                    value={assists}
                    onChange={(e) => setAssists(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={passes}
                    onChange={(e) => setPasses(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Saved</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals saved"
                    value={goalsSaved}
                    onChange={(e) => setGoalsSaved(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Interceptions</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={interceptionsPerGame}
                    onChange={(e) => setInterceptionsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Tackels</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={tackelsPerGame}
                    onChange={(e) => setTackelsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Shots</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takels made in per game"
                    value={shotsPerGame}
                    onChange={(e) => setShotsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={keyPassesPerGame}
                    onChange={(e) => setKeyPassesPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Dribbles Completed</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={dribblesPerGame}
                    onChange={(e) => setDribblesPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Clean Sheets</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the clean sheets made in per game"
                    value={cleanSheetsPerGame}
                    onChange={(e) => setCleanSheetsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Yellow Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of yellow card"
                    value={yellowCard}
                    onChange={(e) => setYellowCard(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Red Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of red card"
                    value={redCard}
                    onChange={(e) => setRedCard(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Achievement</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the key achievements"
                    value={keyAchievements}
                    onChange={(e) => setKeyAchievements(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>

              {/* <Row className='mb-3'>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Do you want endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                  />
                </Form.Group> */}

              {/* {checkedEndorsement && teamId === null &&
                  <Form.Group as={Col} column sm="4">
                    <Form.Label className='custom-label'>Email</Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </Form.Group>
                } */}

              {/* <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Do you want Endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    onChange={handleCheckedEndorsement}
                  />
                  {checkedEndorsement && !isEmailFieldVisible &&
                    <Form.Text muted>
                      Endorsement request will be sent to your club also.
                    </Form.Text>
                  }
                </Form.Group>

                {checkedEndorsement && isEmailFieldVisible &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Email</Form.Label>
                    <Stack direction="horizontal" gap={2}>
                      <Form.Control
                        className='input'
                        type="email"
                        size='lg'
                        placeholder="Enter the email address"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                      <Button
                        variant="primary"
                        size='lg'
                        onClick={handleShowAskForRegistrationModal}
                      >
                        Invite
                      </Button>
                    </Stack>
                    <Form.Text muted>
                      As your club is not registered, you can send the registeration link.
                    </Form.Text>
                  </Form.Group>
                }
              </Row> */}

              <Card>
                <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      Endorsement
                    </div>
                    <div>
                      <Form.Check
                        type="checkbox"
                        size='lg'
                        label="Do you want Endorsement?"
                        checked={checkedEndorsement}
                        onChange={handleCheckedEndorsement}
                      />
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className='mb-3'>
                    {checkedEndorsement && isEmailFieldVisible &&
                      <Form.Group as={Col} sm="4">
                        <Form.Label className='custom-label'>Your club is not registered. Do you want to invite?</Form.Label>
                        <Stack direction="horizontal" gap={2}>
                          <Form.Control
                            className='input'
                            type="email"
                            size='lg'
                            placeholder="Enter the email address"
                            value={askforRegistrationEmail}
                            onChange={(e) => setAskforRegistrationEmail(e.target.value)}
                          />
                          <Button
                            variant="primary"
                            size='lg'
                            onClick={handleOnClickSendInvitation}
                          >
                            Invite
                          </Button>
                        </Stack>
                        <Form.Text muted>
                          {emailError}
                        </Form.Text>
                      </Form.Group>
                    }

                    {checkedEndorsement && !isEmailFieldVisible &&
                      <Form.Group as={Col} sm="4">
                        <Form.Label className='custom-label'>Do you want to send endorsement to club also?</Form.Label>
                        <Form.Check
                          type="checkbox"
                          size='lg'
                          label="Yes"
                          checked={checkedClubEndorsement}
                          onChange={handleCheckedClubEndorsement}
                        />
                      </Form.Group>
                    }
                  </Row>


                  {checkedEndorsement &&
                    <>
                      <Card>
                        <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>
                          <div className='d-flex justify-content-between'>
                            <div>
                              Personal Endorsement
                            </div>
                            <div>
                              <Button variant="primary" onClick={handleAddFieldEndorsement}>
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  // color='green'
                                  // size = '2x'
                                  style={{ cursor: 'pointer', marginRight: 5 }}
                                />
                                Add Endorsement Request
                              </Button>
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          {personNameEndorsement.map((val, i) =>
                            <Row>
                              <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Profile Type</Form.Label>
                                <Form.Select
                                  className='input'
                                  size='lg'
                                  value={val.type}
                                  onChange={(e) => handleChangeEndorsementType(e, i)}
                                >
                                  <option value=" ">Open this select menu</option>
                                  <option value="Player">Player</option>
                                  <option value="Coach">Coach</option>
                                  <option value="Agent">Agent</option>
                                </Form.Select>
                              </Form.Group>

                              <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Name
                                  <span className='text-danger'> * </span>
                                </Form.Label>
                                <Stack direction="horizontal" gap={2}>
                                  <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter the name of a person"
                                    name="to_endorser_email"
                                    value={val.to_endorser_email}
                                    onChange={(e) => handleChangeEndorsement(e, i)}
                                  />
                                  <Button
                                    variant="primary"
                                    size='lg'
                                    onClick={(e) => handleOnClickSearchEndorsementFilter(val.type, i)}
                                  >
                                    Search
                                  </Button>
                                </Stack>
                              </Form.Group>
                              <Form.Group as={Col} sm="4">
                                <div className='d-flex justify-content-end mt-5'>
                                  {/* <div className='ms-2'>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color='green'
                                    style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                                    onClick={handleAddFieldEndorsement}
                                  />
                                </div> */}
                                  <div className='ms-2'>
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      color='red'
                                      style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                      onClick={() => handleDeleteEndorsement(i)}
                                    />
                                  </div>
                                </div>
                              </Form.Group>
                            </Row>
                          )}
                        </Card.Body>
                      </Card>
                      <Row className='mb-3'>
                        <Form.Group as={Col}>
                          <Form.Label className='custom-label'>Comments</Form.Label>
                          <Form.Control
                            className='input'
                            // type="text"
                            // size='lg'
                            placeholder="Enter the comments"
                            // name="text"
                            value={comments}
                            // onChange={(e) => setComments(e.target.value)}
                            onChange={onChangeHandleComments}
                            as="textarea"
                            rows={5}
                          />
                        </Form.Group>
                      </Row>
                    </>
                  }
                </Card.Body>
              </Card>

              {/* {checkedEndorsement &&
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Comments</Form.Label>
                    <Form.Control
                      className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      // onChange={(e) => setComments(e.target.value)}
                      onChange={onChangeHandleComments}
                      as="textarea"
                      rows={5}
                    />
                  </Form.Group>
                </Row>
              } */}

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          {!isSpinnerLoadingVisible &&
            <>
              <Button variant="secondary" onClick={handleAddCareerHistoryModalClose}>Close</Button>
              <Button variant="primary" onClick={handleAddPlayerClubHistory}>Save Changes</Button>
            </>
          }
        </Modal.Footer>
      </Modal >

      <Modal show={showEditCareerHistoryModal} onHide={handleEditCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>
            Edit Career History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedCareerHistory}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2013"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>Type
                    <span className='custom-label'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={leagueType}
                    // onChange={(e) => setLeagueType(e.target.value)}
                    onChange={handleOnChangeRegionType}
                    // style={{ fontSize: 11, padding: 10 }}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  >
                    <option value=''>Open this select menu</option>
                    <option value="International">International</option>
                    <option value="Domestic">Domestic</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {typeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Summary</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the summary regarding your career as player"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {leagueType === 'Domestic' &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Country Name
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Select
                      size='lg'
                      value={countryName}
                      onChange={(e) => setCountryName(e.target.value)}
                      disabled={inputFieldDisabled}
                      required
                    >
                      <option value="">Open this select menu</option>
                      {optionsCountry.map((country) => (
                        <option value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {countryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Club Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a club"
                    value={teamName}
                    onChange={e => handleSearchFilter(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  {isTeamLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchTeamLOVData.length > 0 ?
                            searchTeamLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectTeam(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsTeamLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {teamError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>League
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a league"
                    value={leagueName}
                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  {isLeagueLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchLeagueLOVData.length > 0 ?
                            searchLeagueLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsLeagueLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {leagueError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Games Played
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={gamesPlayed}
                    onChange={(e) => setGamesPlayed(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {gamesPlayedError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Scored</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals scored"
                    value={goalsScored}
                    onChange={(e) => setGoalsScored(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Assists</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of assists"
                    value={assists}
                    onChange={(e) => setAssists(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={passes}
                    onChange={(e) => setPasses(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Saved</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals saved"
                    value={goalsSaved}
                    onChange={(e) => setGoalsSaved(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Interceptions</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={interceptionsPerGame}
                    onChange={(e) => setInterceptionsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Tackels</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={tackelsPerGame}
                    onChange={(e) => setTackelsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Shots</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takels made in per game"
                    value={shotsPerGame}
                    onChange={(e) => setShotsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={keyPassesPerGame}
                    onChange={(e) => setKeyPassesPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Dribbles Completed</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={dribblesPerGame}
                    onChange={(e) => setDribblesPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Clean Sheets</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the clean sheets made in per game"
                    value={cleanSheetsPerGame}
                    onChange={(e) => setCleanSheetsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Yellow Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of yellow card"
                    value={yellowCard}
                    onChange={(e) => setYellowCard(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Red Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of red card"
                    value={redCard}
                    onChange={(e) => setRedCard(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Achievement</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the key achievements"
                    value={keyAchievements}
                    onChange={(e) => setKeyAchievements(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>

              {/* <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Do you want Endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    // onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                    onChange={handleCheckedEndorsement}
                    disabled={inputFieldDisabled}
                  />
                  {checkedEndorsement && !isEmailFieldVisible &&
                    <Form.Text muted>
                      Endorsement request will be sent to your club also.
                    </Form.Text>
                  }
                </Form.Group>

                {checkedEndorsement && isEmailFieldVisible &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Email
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                    />
                    <Form.Text muted>
                      As your club is not registered in bscoutd, please enter the email address of the club.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Email cannot be empty
                    </Form.Control.Feedback>
                  </Form.Group>
                }
              </Row> */}

              <Card>
                <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>
                  <div className='d-flex justify-content-between'>
                    <div>Endorsement</div>
                    <div>
                      <Form.Check
                        type="checkbox"
                        size='lg'
                        label="Do you want endorsement?"
                        checked={checkedEndorsement}
                        // onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                        onChange={handleCheckedEndorsement}
                        disabled={inputFieldDisabled}
                      />
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className='mb-3'>
                    {checkedEndorsement && isEmailFieldVisible &&
                      <Form.Group as={Col} sm="4">
                        <Form.Label className='custom-label'>Your club is not registered. Do you want to invite?</Form.Label>
                        <Stack direction="horizontal" gap={2}>
                          <Form.Control
                            className='input'
                            type="email"
                            size='lg'
                            placeholder="Enter the email address"
                            value={askforRegistrationEmail}
                            onChange={(e) => setAskforRegistrationEmail(e.target.value)}
                          />
                          <Button
                            variant="primary"
                            size='lg'
                            onClick={handleOnClickSendInvitation}
                          >
                            Invite
                          </Button>
                        </Stack>
                        <Form.Text muted>
                          {emailError}
                        </Form.Text>
                      </Form.Group>
                    }

                    {checkedEndorsement && !isEmailFieldVisible &&
                      <Form.Group as={Col} sm="4">
                        <Form.Label className='custom-label'>Do you want to send endorsement to club?</Form.Label>
                        <Form.Check
                          type="checkbox"
                          size='lg'
                          label="Yes"
                          checked={checkedClubEndorsement}
                          onChange={handleCheckedClubEndorsement}
                          disabled={isEndorsementRequestClubDisabled}
                        />
                      </Form.Group>
                    }
                  </Row>

                  {checkedEndorsement &&
                    <Card>
                      <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>
                        <div className='d-flex justify-content-between'>
                          <div>
                            Personal Endorsement
                          </div>
                          <div>
                            <Button variant="primary" onClick={handleAddFieldEditEndorsement}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                // color='green'
                                // size = '2x'
                                style={{ cursor: 'pointer', marginRight: 5 }}
                              />
                              Add Endorsement Request
                            </Button>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>

                        <Table striped responsive bordered hover>
                          <thead>
                            <tr>
                              <th>To Endorser</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Remarks</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playerEndorsementRequest && playerEndorsementRequest.length > 0 ?
                              playersEndorsementRequestList :
                              <tr>
                                <th colSpan={5} className='text-center'>
                                  No endorsement request found
                                </th>
                              </tr>
                            }
                          </tbody>
                        </Table>

                        {updatePersonNameEndorsement.map((val, i) =>
                          <Row>
                            <Form.Group as={Col} sm="4">
                              <Form.Label className='custom-label'>Profile Type</Form.Label>
                              <Form.Select
                                className='input'
                                size='lg'
                                value={val.type}
                                onChange={(e) => handleChangeEditEndorsementType(e, i)}
                              >
                                <option value=''>Open this select menu</option>
                                <option value="Player">Player</option>
                                <option value="Coach">Coach</option>
                                <option value="Agent">Agent</option>
                              </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} sm="4">
                              <Form.Label className='custom-label'>Name
                                <span className='text-danger'> * </span>
                              </Form.Label>
                              <Stack direction="horizontal" gap={2}>
                                <Form.Control
                                  className='input'
                                  type="text"
                                  size='lg'
                                  placeholder="Enter the name of a person"
                                  name="to_endorser_email"
                                  value={val.to_endorser_email}
                                  onChange={(e) => handleChangeEditEndorsement(e, i)}
                                />
                                <Button
                                  variant="primary"
                                  size='lg'
                                  onClick={() => handleOnClickSearchEditEndorsementFilter(val.type, i)}
                                >
                                  Search
                                </Button>
                              </Stack>
                            </Form.Group>
                            <Form.Group as={Col} sm="4">
                              <div className='d-flex justify-content-end mt-5'>
                                {/* <div className='ms-2'>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color='green'
                                    style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                                    onClick={handleAddFieldEditEndorsement}
                                  />
                                </div> */}
                                <div className='ms-2'>
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    color='red'
                                    style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                    onClick={() => handleDeleteEditEndorsement(i)}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Row>
                        )}

                      </Card.Body>
                    </Card>
                  }

                  {checkedEndorsement &&
                    <Row className='mb-3'>
                      <Form.Group as={Col}>
                        <Form.Label className='custom-label'>Comments</Form.Label>
                        <Form.Control
                          // className='input'
                          // type="text"
                          // size='lg'
                          placeholder="Enter the comments"
                          // name="text"
                          value={comments}
                          // onChange={(e) => setComments(e.target.value)}
                          onChange={onChangeHandleEditComments}
                          as="textarea"
                          rows={5}
                        // disabled={inputFieldDisabled}
                        />
                      </Form.Group>
                    </Row>
                  }
                </Card.Body>
              </Card>

              {/* {checkedEndorsement && 
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Comments</Form.Label>
                    <Form.Control
                      // className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      // onChange={(e) => setComments(e.target.value)}
                      onChange={onChangeHandleEditComments}
                      as="textarea"
                      rows={5}
                      // disabled={inputFieldDisabled}
                    />
                  </Form.Group>
                </Row>
              } */}

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          {!isSpinnerLoadingVisible &&
            <>
              <Button variant="secondary" onClick={handleEditCareerHistoryModalClose}>Close</Button>
              <Button variant="primary" onClick={handleUpdatePlayerClubHistory}>Save Changes</Button>
            </>
          }
        </Modal.Footer>
      </Modal >

      <Modal show={showAddVideosModal} onHide={handleAddVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleSubmitVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditVideosModal} onHide={handleEditVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleUpdateVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, marginLeft: 40, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.first_name} {item.last_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <Container>
        <Row>

          <ProfileBuilderNavBar
            playerIsActive={selectedPlayerSwitchOption}
            coachIsActive={selectedCoachSwitchOption}
            agentIsActive={selectedAgentSwitchOption}
          />

          <Col xs={9}>
            <Card className='mt-3'>
              <Card.Body>
                <h2 className='text-center'>Player</h2>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  justify
                  variant='underline'
                >
                  <Tab eventKey="general" title="GENERAL">
                    <Card>
                      <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Player Information</Card.Header>
                      <Card.Body>
                        <Table striped responsive bordered hover>
                          <thead>
                            <tr>
                              <th>Edit</th>
                              <th>Name</th>
                              <th>Primary Position</th>
                              <th>Secondary Position</th>
                              <th>Preferred Foot</th>
                              <th>Top Speed</th>
                              <th>My Worth</th>
                              <th>Is Open for Hiring</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playerInfo !== '' ?
                              <tr>
                                <td>
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    color='#2222e785'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      getPlayerInfo(playerInfo.id);
                                      setShowEditGeneralModal(true);
                                    }}
                                  />
                                </td>
                                <td>{userPersonalData.first_name} {userPersonalData.last_name}</td>
                                <td>{playerInfo.primary_position}</td>
                                <td>{playerInfo.secondary_position}</td>
                                <td>{playerInfo.preferred_foot}</td>
                                <td>{playerInfo.top_speed}</td>
                                <td>{playerInfo.my_worth}</td>
                                <td>{playerInfo.is_open_for_hiring ? 'Yes' : 'No'}</td>
                              </tr>
                              :
                              <tr>
                                <td colSpan={8} className='text-center'>
                                  No rows found
                                </td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Tab>

                  <Tab eventKey="careerhistory" title="CAREER HISTORY">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddCareerHistoryModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Career Histories</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Club Name</th>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>League Name</th>
                                <th>Games Played</th>
                                <th>Goals Scored</th>
                                <th>Goals Saved</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playerClub && playerClub.length > 0 ?
                                playerClubList :
                                <tr>
                                  <td colSpan={9} className='text-center'>
                                    No rows found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of personal achievements</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>Achievement Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playerClub && playerClub.length > 0 ?
                                personalAchievementList :
                                <tr>
                                  <th colSpan={3} className='text-center'>
                                    No rows found
                                  </th>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                  <Tab eventKey="videos" title="VIDEOS">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddVideosModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of videos</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Video Title</th>
                                <th>URL</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                videoClip && videoClip.length > 0 ?
                                  videoClipList
                                  :
                                  <tr>
                                    <td colSpan={4} className='text-center'>
                                      No rows found
                                    </td>
                                  </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                </Tabs>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  )
}
