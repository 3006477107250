// export const ROOT_URL = 'http://141.148.200.229:8000';
// export const BASE_URL = 'http://141.148.200.229:8000/football/api';
// export const BASE_MEDIA_URL = 'http://141.148.200.229:8000';
export const ROOT_URL = 'https://django.bscoutd.com';
export const BASE_URL = 'https://django.bscoutd.com/football/api';
export const BASE_MEDIA_URL = 'https://django.bscoutd.com';
// export const ROOT_URL = 'http://127.0.0.1:8000';
// export const BASE_URL = 'http://127.0.0.1:8000/football/api';
// export const BASE_MEDIA_URL = 'http://127.0.0.1:8000';
export const LOGIN_URL = `${BASE_URL}/login/`;
export const FACEBOOK_LOGIN_URL = `${ROOT_URL}/api/auth/facebook/`;
export const GOOGLE_LOGIN_URL = `${ROOT_URL}/api/auth/google/`;
export const REGISTER_URL = `${BASE_URL}/register/`;
export const REGISTER_AS_INSTITUTE_URL = `${BASE_URL}/register/institute/`;
export const TOKEN_REFRESH_URL = `${BASE_URL}/token/refresh/`;
export const TOKEN_VERIFY_URL = `${BASE_URL}/token/verify/`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword/`;
export const RESET_PASSWORD_URL = `${BASE_URL}/password_reset/`;
export const RESET_PASSWORD_CONFIRM_URL = `${BASE_URL}/password_reset/confirm/`;
export const USER_URL = `${BASE_URL}/user`;
export const INSTITUITION_INFO_URL = `${BASE_URL}/instituitioninfo`;
export const CHANGESPORTPROFILE = `${BASE_URL}/changesportprofile/`;
export const SPORTPROFILETYPECHANGEUSERSTATUS_URL = `${BASE_URL}/sportprofilechangeuserstatus/`;
export const SPORTPROFILETYPE_URL = `${BASE_URL}/sportprofiletype`;
export const SPORTPROFILE_URL = `${BASE_URL}/sportprofile/`;
export const GETPOSTITEM_URL = `${BASE_URL}/getpostitem/`;
export const POSTITEM_URL = `${BASE_URL}/postitem/`;
export const COMMENTS_URL = `${BASE_URL}/comments/`;
export const LIKES_URL = `${BASE_URL}/likes/`;
export const NEWS_URL = `${BASE_URL}/news/`;
export const NEWS_ALL_URL = `${BASE_URL}/newsall`;
export const CREATENETWORKREQUEST_URL = `${BASE_URL}/createnetworkrequest`;
export const NETWORKREQUEST_URL = `${BASE_URL}/networkrequest`;
export const NETWORKCONNECT_URL = `${BASE_URL}/networkconnect`;
export const NETWORKCONNECTIONS_URL = `${BASE_URL}/networkconnections/`;
export const CONNECTREQUEST_URL = `${BASE_URL}/connectrequest`;
export const SEARCHALLUSER_URL = `${BASE_URL}/search/alluser?`;
export const SEARCHPLAYER_URL = `${BASE_URL}/search/player?`;
export const SEARCHCOACH_URL = `${BASE_URL}/search/coach?`;
export const SEARCHAGENT_URL = `${BASE_URL}/search/agent?`;
export const SEARCHINSTITUITION_URL = `${BASE_URL}/search/instituition?`;
export const ADDRESS_URL = `${BASE_URL}/address`;
export const PROFILEDESC_URL = `${BASE_URL}/profiledescription`;
export const COACH_URL = `${BASE_URL}/coach`;
export const PLAYER_URL = `${BASE_URL}/player`;
export const INSTITUITION_URL = `${BASE_URL}/instituition`;
export const GET_INSTITUITION_URL = `${BASE_URL}/get/instituition/`;
export const INSTITUITIONLICENSECREATE_URL = `${BASE_URL}/instituitionlicensecreate/`;
export const INSTITUITIONLICENSEUPDATE_URL = `${BASE_URL}/instituitionlicenseupdate/`;
export const INSTITUITIONOFFICEBEARER_URL = `${BASE_URL}/instituitionofficebearer`;
export const INSTITUITIONHISTORYCREATE_URL = `${BASE_URL}/instituitioncareerhistorycreate/`;
export const INSTITUITIONHISTORYUPDATE_URL = `${BASE_URL}/instituitioncareerhistoryupdate/`;
export const INSTITUITIONHISTORY_URL = `${BASE_URL}/instituitionhistory`;
export const COACHCAREERHISTORY_URL = `${BASE_URL}/coachcareerhistory`;
export const PLAYERCAREERHISTORY_URL = `${BASE_URL}/playercareerhistory`;
export const CLUB_URL = `${BASE_URL}/club`;
export const PLAYERHISTORYCREATE_URL = `${BASE_URL}/playerhistorycreate/`;
export const PLAYERHISTORYUPDATE_URL = `${BASE_URL}/playerhistoryleagueupdate/`;
export const PLAYERHISTORYTEAMLEAGUEUPDATE_URL = `${BASE_URL}/playerhistoryteamleagueupdate/`;
export const PLAYERHISTORY_URL = `${BASE_URL}/playerhistory/`;
export const PLAYER = `${BASE_URL}/playerhistory/`;
export const AGENTINSIDE_URL = `${BASE_URL}/agentinside`;
export const AGENT_URL = `${BASE_URL}/agent`;
export const AGENTOUTSIDE_URL = `${BASE_URL}/agentoutside`;
export const REFERENCEINSIDE_URL = `${BASE_URL}/referenceinside`;
export const REFERENCEOUTSIDE_URL = `${BASE_URL}/referenceoutside`;
export const FOOTBALLTOURNAMENT_URL = `${BASE_URL}/footballtournament`;
export const ACHEIVEMENTS_URL = `${BASE_URL}/acheivements`;
export const PERSONALACHIEVEMENTS_URL = `${BASE_URL}/personalachievements`;
export const VIDEOCLIP_URL = `${BASE_URL}/videoclip`;
export const VERIFYREQUEST_URL = `${BASE_URL}/verifyrequest/`;
export const SENDMAIL_URL = `${BASE_URL}/send/mail/`;
export const CHANGEPRESENTADDRESS_URL = `${BASE_URL}/changepresentaddress/`;
export const ADDADDRESS_URL = `${BASE_URL}/addaddress/`;
export const COUNTRY_URL = `${BASE_URL}/country/`;
export const LEAGUES_URL = `${BASE_URL}/leagues/`;
export const TEAMS_URL = `${BASE_URL}/teams/`;
export const SPORTLICENSE_URL = `${BASE_URL}/sportlicense/`;
export const COACHLICENSE_URL = `${BASE_URL}/coachlicense`;
export const AGENTLICENSE_URL = `${BASE_URL}/agentlicense`;
export const INSTITUITIONLICENSE_URL = `${BASE_URL}/instituitionlicense`;
export const FOOTBALLCOACHUPDATE_URL = `${BASE_URL}/footballcoachupdate/`;
export const FOOTBALLCOACHLICENSECREATE_URL = `${BASE_URL}/footballcoachlicensecreate/`;
export const FOOTBALLCOACHLICENSEUPDATE_URL = `${BASE_URL}/footballcoachlicenseupdate/`;
export const FOOTBALLCOACHCAREERHISTORYCREATE_URL = `${BASE_URL}/footballcoachcareerhistorycreate/`;
export const FOOTBALLCOACHCAREERHISTORYLEAGUECREATE_URL = `${BASE_URL}/footballcoachcareerhistoryleaguecreate/`;
export const FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL = `${BASE_URL}/footballcoachcareerhistoryteamandleagueupdate/`;
export const FOOTBALLCOACHCAREERHISTORYUPDATE_URL = `${BASE_URL}/footballcoachcareerhistoryandleagueupdate/`;
export const FOOTBALLAGENTUPDATE_URL = `${BASE_URL}/footballagent/`;
export const FOOTBALLAGENTLICENSECREATE_URL = `${BASE_URL}/footballagentlicensecreate/`;
export const FOOTBALLAGENTLICENSEUPDATE_URL = `${BASE_URL}/footballagentlicenseupdate/`;
export const FOOTBALLAGENTCAREERHISTORY_URL = `${BASE_URL}/agentcareerhistory/`;
export const AGENTCAREERHISTORY_URL = `${BASE_URL}/footballagentcareerhistory/`;
export const AGENTCAREERHISTORYUPDATE_URL = `${BASE_URL}/footballagentcareerhistoryupdate/`;
export const AGENTPLAYERSCOACHESENDORSEMENTUPDATE_URL = `${BASE_URL}/footballagentplayerscoachesupdate/`;
export const FOOTBALLPLAYERSANDCOACHESUNDERAGENT_URL = `${BASE_URL}/playerandcoachesunderagent/`;
export const GETPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL = `${BASE_URL}/getplayerandcoachesendorsementunderagent/`;
export const PLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL = `${BASE_URL}/playerandcoachesendorsementunderagent/`;
export const CREATEPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL = `${BASE_URL}/createplayerscoachesendorsementunderagent/`;
export const GETFOOTBALLPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL = `${BASE_URL}/getfootballplayerandcoachesendorsementunderagent/`;
export const FOOTBALLPLAYERENDORSEMENTREQUEST_URL = `${BASE_URL}/footballplayerendorsementrequest/`;
export const GET_FOOTBALLPLAYERENDORSEMENTREQUEST_URL = `${BASE_URL}/getfootballplayerendorsementrequest/`;
export const GET_ALLFOOTBALLPLAYERENDORSEMENTREQUESTUNDERPARTICULARUSER_URL = `${BASE_URL}/getplayerendorsementrequest/`;
export const FOOTBALLCOACHENDORSEMENTREQUEST_URL = `${BASE_URL}/footballcoachendorsementrequest/`;
export const GET_ALLFOOTBALLCOACHENDORSEMENTREQUESTUNDERPARTICULARUSER_URL = `${BASE_URL}/getcoachendorsementrequest/`;
export const GET_FOOTBALLCOACHENDORSEMENTREQUEST_URL = `${BASE_URL}/getfootballcoachendorsementrequest/`;
// export const EVENT_MEDIA_URL = `${BASE_MEDIA_URL}/media/event`;

export const columns = [
    {
        name: 'Player',
        selector: row => row.user.first_name + ' ' + row.user.last_name,
        sortable: true,
    },
    {
        name: 'Team',
        selector: row => row.current_club,
        sortable: true,
    },
    {
        name: 'Primary position',
        selector: row => row.primary_position,
        sortable: true,
    },
    {
        name: 'Secondary position',
        selector: row => row.secondary_position,
        sortable: true,
    },
    {
        name: 'Age',
        selector: row => row.user.age,
        sortable: true,
    },
    {
        name: 'Weight in kg',
        selector: row => row.user.weight,
        sortable: true,
    },
    {
        name: 'Height in cm',
        selector: row => row.user.height,
        sortable: true,
    },
    {
        name: 'Preferred foot',
        selector: row => row.preferred_foot,
        sortable: true,
    },
    {
        name: 'Birth country',
        selector: row => row.user.citizenship,
        sortable: true,
    },
];

export const columnsCoach = [
    {
        name: 'Coach',
        selector: row => row.user.first_name + ' ' + row.user.last_name,
        sortable: true,
    },
    {
        name: 'Team',
        selector: row => row.current_team,
        sortable: true,
    },
    {
        name: 'From',
        selector: row => row.from_date,
        sortable: true,
    },
    {
        name: 'To',
        selector: row => row.to_date,
        sortable: true,
    },
    {
        name: 'Age',
        selector: row => row.user.age,
        sortable: true,
    },
    {
        name: 'Weight in kg',
        selector: row => row.user.weight,
        sortable: true,
    },
    {
        name: 'Height in cm',
        selector: row => row.user.height,
        sortable: true,
    },
    {
        name: 'Playoffs games coached in',
        selector: row => row.playoffs_games_coached_in,
        sortable: true,
    },
    {
        name: 'Playoffs games won',
        selector: row => row.playoffs_games_won,
        sortable: true,
    },
    {
        name: 'Playoffs games lost',
        selector: row => row.playoffs_games_lost,
        sortable: true,
    },
    {
        name: 'Birth country',
        selector: row => row.user.citizenship,
        sortable: true,
    },
];

export const optionsCountry = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "land Islands", label: "land Islands" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "AndorrA", label: "AndorrA" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    { value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    { value: "Congo, The Democratic Republic of the", label: "Congo, The Democratic Republic of the" },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Cote D\"Ivoire", label: "Cote D\"Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    { value: "French Southern Territories", label: "French Southern Territories" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Heard Island and Mcdonald Islands", label: "Heard Island and Mcdonald Islands" },
    { value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea, Democratic People\"S Republic of", label: "Korea, Democratic People\"S Republic of" },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Lao People\"S Democratic Republic", label: "Lao People\"S Democratic Republic" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    { value: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "RWANDA", label: "RWANDA" },
    { value: "Saint Helena", label: "Saint Helena" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania, United Republic of", label: "Tanzania, United Republic of" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    { value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" }
];


export const optionsPosition = [
    { value: 'Goalkeeper', label: 'Goalkeeper' },
    { value: 'Right Fullback', label: 'Right Fullback' },
    { value: 'Left Fullback', label: 'Left Fullback' },
    { value: 'Center Back', label: 'Center Back' },
    { value: 'Right Midfielder or Winger', label: 'Right Midfielder or Winger' },
    { value: 'Center Midfielder', label: 'Center Midfielder' },
    { value: 'Attacking Midfielder', label: 'Attacking Midfielder' },
    { value: 'Left Midfielder or Winger', label: 'Left Midfielder or Winger' },
    { value: 'Striker', label: 'Striker' },
];

export const optionsPreferredFoot = [
    { value: 'Left', label: 'Left' },
    { value: 'Right', label: 'Right' },
];

export const optionsClubs = [
    { value: 'Acacia Ridge', label: 'Acacia Ridge', id: 'club-AUS1' },
    { value: 'Across The Waves', label: 'Across The Waves', id: 'club-AUS2' },
    { value: 'Adamstown Rosebud', label: 'Adamstown Rosebud', id: 'club-AUS3' },
    { value: 'Adelaide Blue Eagles', label: 'Adelaide City', id: 'club-AUS4' },
    { value: 'Adelaide Cobras', label: 'Adelaide Comets', id: 'club-AUS5' },
    { value: 'Adelaide Croatia Raiders', label: 'Adelaide Croatia Raiders', id: 'club-AUS6' },
    { value: 'Adelaide Hills Hawks', label: 'Adelaide Hills Hawks', id: 'club-AUS7' },
    { value: 'Adelaide Olympic', label: 'Adelaide Olympic', id: 'club-AUS8' },
    { value: 'Adelaide United', label: 'Adelaide United', id: 'club-AUS9' },
    { value: 'Adelaide University', label: 'Adelaide University', id: 'club-AUS10' },
    { value: 'Adelaide Victory', label: 'Adelaide Victory', id: 'club-AUS11' },
    { value: 'Adelaide Vipers', label: 'Adelaide Vipers', id: 'club-AUS12' },
    { value: 'Balgownie Rangers', label: 'Balgownie Rangers', id: 'club-AUS13' },
    { value: 'Ballarat City', label: 'Ballarat City', id: 'club-AUS14' },
    { value: 'Bankstown City', label: 'Bankstown City', id: 'club-AUS15' },
    { value: 'Banyule City', label: 'Banyule City', id: 'club-AUS16' },
    { value: 'Bardon Latrobe', label: 'Bardon Latrobe', id: 'club-AUS17' },
];

export const optionsLeague = [
    { value: 'A-League', label: 'A-League' },
    { value: 'Australia Cup', label: 'Australia Cup' },
    { value: 'Brisbane Cup', label: 'Brisbane Cup' },
    { value: 'Brisbane Premier League', label: 'Brisbane Premier League' },
    { value: 'Capital NPL 1', label: 'Capital NPL 1' },
    { value: 'National Premier League', label: 'National Premier League' },
    { value: 'Queensland NPL', label: 'Queensland NPL' },
    { value: 'South Australia NPL', label: 'South Australia NPL' },
    { value: 'Victoria NPL', label: 'Victoria NPL' },
    { value: 'Western Australia NPL', label: 'Western Australia NPL' },
];

export const optionsPriority = [
    { value: '1', label: 'High' },
    { value: '2', label: 'Middle' },
    { value: '3', label: 'Low' },
];